import {
  Autocomplete,
  GoogleMap,
  Marker,
  useLoadScript,
} from '@react-google-maps/api';
import { IconMarkerMaps, IconMap, IconClose } from 'assets';
import { getLokasiPemberianVaksin } from 'config/Redux/action';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Input } from '../../atoms';

const libraries = ['places'];

export default function Newnew(props) {
  const [lokasiVaksin, setLokasiVaksin] = useState([]);
  const [fixLokasi, setFixLokasi] = useState('');
  const [fixAlamat, setFixAlamat] = useState('');
  const [center, setCenter] = useState({ lat: 0, lng: 0 });
  const [autoComplete, setAutoComplete] = useState(null);
  const [position, setPosition] = useState({
    lat: 0,
    lng: 0,
  });
  const [showMaps, setShowMaps] = useState(false);
  const dispatch = useDispatch();

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: 'AIzaSyB_73hYRCjyR5yeJXgQHcYjun6JGYGpM8E',
    libraries,
    language: 'id',
  });

  const [map, setMap] = React.useState(null);

  // const onLoad = useCallback(function callback(map) {
  //   const bounds = new window.google.maps.LatLngBounds();
  //   map.fitBounds(bounds);
  //   setMap(map);
  // }, []);

  const onUnmount = useCallback(function callback(map) {
    setMap(null);
  }, []);

  const onLoadAutoComplete = (autocomplete) => {
    setAutoComplete(autocomplete);
  };

  const onPlaceChangedAutoComplete = () => {
    if (autoComplete !== null) {
      const tes = autoComplete.getPlace();

      setFixAlamat(tes.formatted_address);
      setPosition({
        ...position,
        lat: tes.geometry.location.lat(),
        lng: tes.geometry.location.lng(),
      });

      setCenter({
        ...center,
        lat: tes.geometry.location.lat(),
        lng: tes.geometry.location.lng(),
      });
    } else {
    }
  };

  const lokasiBaru = (latlng) => {
    var geocoder = (geocoder = new window.google.maps.Geocoder());
    return geocoder.geocode({ latLng: latlng }, function (results, status) {
      if (status == window.google.maps.GeocoderStatus.OK) {
        if (results[1]) {
          setFixAlamat(results[1].formatted_address);
        }
      }
    });
  };

  const handleDrag = (drag) => {
    setPosition({
      ...position,
      lat: drag.latLng.lat(),
      lng: drag.latLng.lng(),
    });
  };

  const currentLocation = () => {
    navigator.geolocation.getCurrentPosition((position) => {
      setCenter({
        ...center,
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      });
    });
  };

  useEffect(() => {
    const _getData = async () => {
      const tempatVaksinasi = await dispatch(
        getLokasiPemberianVaksin('/staff/service-locations')
      );
      setLokasiVaksin(tempatVaksinasi);
    };
    _getData();
    currentLocation();

    return () => {
      document.removeEventListener('mouseleave', _getData);
    };
  }, []);

  if (loadError) return 'Error loading maps';
  if (!isLoaded) return 'Loading maps';

  return (
    <div className="h-screen overflow-hidden flex flex-col  pt-11 pb-7 px-9 bg-white">
      <div className="max-h-screen flex-1 overflow-y-scroll scrollbar-none pb-9 px-1">
        <div className="md:block lg:flex mt-4 min-w-full pb-4">
          <div className="w-full lg:w-6/12 md:mt-10 lg:my-0">
            <h2 className="font-inter font-bold text-lg text-black mb-4">
              Jadwal Vaksin
            </h2>

            <Input
              withDropdown
              label="Tempat Pemberian Vaksin"
              type="text"
              name="lokasi-vaksin"
              className="mb-5"
              options={lokasiVaksin}
              readOnly
              fixValue={(value) => setFixLokasi(value.id)}
            />

            {fixLokasi === 2 && (
              <>
                {showMaps && (
                  <div className="absolute inset-0 bg-black bg-opacity-60 z-20 flex flex-col justify-center items-center">
                    <div className="bg-white p-4 rounded-10">
                      <div className="flex justify-end mb-5">
                        <Button
                          type="button-icon"
                          icon={IconClose}
                          alt="close-btn"
                          onClick={() => setShowMaps(false)}
                        />
                      </div>
                      <GoogleMap
                        id="searchbox-example"
                        // onLoad={onLoad}
                        // onUnmount={onUnmount}
                        mapContainerStyle={{
                          width: 400,
                          height: 400,
                          display: 'block',
                        }}
                        zoom={15}
                        center={center}
                        options={{ disableDefaultUI: true, zoomControl: true }}
                      >
                        <Marker
                          position={position}
                          visible={
                            position.lat === 0 || position.lng === 0
                              ? false
                              : true
                          }
                          draggable
                          onDrag={(drag) => handleDrag(drag)}
                          onDragEnd={(drag) => lokasiBaru(position)}
                          icon={IconMarkerMaps}
                        />
                      </GoogleMap>
                      <div className="flex justify-center mt-5">
                        <Button
                          title="Simpan"
                          isPrimary
                          className="rounded-3"
                          onClick={(e) => {
                            e.preventDefault();
                          }}
                        />
                      </div>
                    </div>
                  </div>
                )}
                <Autocomplete
                  onLoad={(a) => onLoadAutoComplete(a)}
                  onPlaceChanged={(a) => onPlaceChangedAutoComplete(a)}
                >
                  <div
                    className={`flex items-stretch justify-center mb-5 mt-5`}
                  >
                    <Input
                      withOutline
                      label="Alamat"
                      name="alamat"
                      type="text"
                      placeholder="Alamat"
                      className={`flex-1 mr-5 `}
                      value={fixAlamat}
                      onChange={(e) => setFixAlamat(e.target.value)}
                    />

                    <Button
                      type="button-icon"
                      icon={IconMap}
                      onClick={() => setShowMaps(true)}
                      className="px-4 border bg-blue1 border-blue1 rounded-3 font-inter font-bold text-blue1"
                    />
                  </div>
                </Autocomplete>

                <Input
                  withOutline
                  label="Keterangan Tambahan Alamat"
                  type="text"
                  name="keteranga-alamat"
                  className="mb-5"
                />
              </>
            )}
          </div>
        </div>
      </div>

      <div className="md:block lg:flex lg:justify-between lg:items-center -mx-9 px-10 pt-7 border-t-8 border-blue2">
        <div className="font-inter">
          <h2 className="font-bold text-lg text-black">
            Total Biaya :
            <span className="font-bold text-xl mr-7">Rp. 450. 000</span>
          </h2>
        </div>

        <div className="md:mt-4 md:flex md:justify-between md:-mx-4 lg:mx-0 lg:mt-0">
          <Button
            isBtnOutlineSecondary
            title="Batal"
            className="w-full md:w-1/2 md:mx-4 lg:mx-0 lg:mr-5 lg:w-44 rounded-3"
          />
          <Button
            isPrimary
            title="Buat Antrian"
            className=" w-full md:w-1/2 md:mx-4 lg:mx-0 lg:w-44 rounded-3"
            // onClick={handleClick}
          />
        </div>
      </div>
    </div>
  );
}
