import React, { useEffect, useRef, useState } from 'react';
// import "react-modern-calendar-datepicker/lib/DatePicker.css";
// import DatePicker, { Calendar, utils } from "react-modern-calendar-datepicker";
import '@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css';
import DatePicker, {
  Calendar,
  utils,
} from '@hassanmojab/react-modern-calendar-datepicker';

import { myCustomLocale } from 'utils';
import API from 'config/Axios';
import moment from 'moment';

export default function InputDate({
  type,
  name,
  label,
  className,
  onFocus,
  onBlur,
  onChange,
  onClick,
  value,
  placeholder,
  options,
  fixValue,
  innerRef,
  serviceLocationId,
  withDateNow,
  disableDay,
}) {
  const [showCalendar, setShowCalendar] = useState(false);
  const [isActive, setIsActive] = useState(false);

  const [selectedDay, setSelectedDay] = useState(null);
  const [disabledDays, setDisabledDays] = useState([]);
  const [disableWeekend, setDisableWeekend] = useState(false);

  const _getBlockDates = async () => {
    const blocks = await API.get('/staff/blocked-dates').then(
      (res) => res.data.blockedDates
    );

    const tempDates = [];
    const disable = [];

    blocks.map((block, i) => {
      const splitBlock = block.date.split('-');
      return tempDates.push(splitBlock);
    });

    tempDates.map((day, i) => {
      let disbled = {
        year: parseInt(day[0]),
        month: parseInt(day[1]),
        day: parseInt(day[2]),
      };

      return disable.push(disbled);
    });

    if (serviceLocationId === 1) {
      const tempStartDate = moment().format('YYYY-MM-DD');
      const tempEndDate = moment().add(1, 'years').format('YYYY-MM-DD');
      var start = moment(tempStartDate),
        end = moment(tempEndDate),
        day = 0; // Sunday

      var result = [];
      var current = start.clone();

      while (current.day(7 + day).isBefore(end)) {
        result.push(current.clone());
      }
      const tempResultWeekend = result.map((m) => m.format('YYYY-MM-DD'));

      tempResultWeekend.map((day, i) => {
        const splitDays = day.split('-');

        let disbled = {
          year: parseInt(splitDays[0]),
          month: parseInt(splitDays[1]),
          day: parseInt(splitDays[2]),
        };

        return disable.push(disbled);
      });

      return setDisabledDays(disable);
    } else {
      if (disableWeekend) {
        const tempStartDate = moment().format('YYYY-MM-DD');
        const tempEndDate = moment().add(1, 'years').format('YYYY-MM-DD');
        var start = moment(tempStartDate),
          end = moment(tempEndDate),
          day = 0; // Sunday

        var result = [];
        var current = start.clone();

        while (current.day(7 + day).isBefore(end)) {
          result.push(current.clone());
        }
        const tempResultWeekend = result.map((m) => m.format('YYYY-MM-DD'));

        tempResultWeekend.map((day, i) => {
          const splitDays = day.split('-');

          let disbled = {
            year: parseInt(splitDays[0]),
            month: parseInt(splitDays[1]),
            day: parseInt(splitDays[2]),
          };

          return disable.push(disbled);
        });
        return setDisabledDays(disable);
      }
    }

    setDisabledDays(disable);
  };

  const handleToggleDisableWeek = (e) => {
    setDisableWeekend(e.target.checked);
  };

  const wrapperCalendar = useRef(null);
  const handleClickOutside = (event) => {
    if (wrapperCalendar && !wrapperCalendar.current.contains(event.target)) {
      setShowCalendar(false);
    }
  };

  const handleChange = () => {
    return fixValue(selectedDay);
  };

  useEffect(() => {
    if (disableDay) {
      _getBlockDates();
    }
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [disableWeekend, serviceLocationId]);

  return (
    <div
      className={`outline relative border border-black focus-within:border-blue1 focus-within:text-blue1 rounded-3 ${className}`}
      ref={wrapperCalendar}
      onClick={(e) => handleClickOutside(e)}
    >
      <input
        type={type}
        name={name}
        className={`block p-3 w-full text-sm font-inter appearance-none border-none focus:outline-none focus:ring rounded-3 text-black2 ${
          showCalendar
            ? ''
            : 'relative z-10 bg-transparent placeholder-transparent'
        }`}
        value={value}
        /* old */
        // value={
        //   !selectedDay
        //     ? value
        //     : `${selectedDay.day} - ${selectedDay.month} - ${selectedDay.year}`
        // }
        /* old */
        onClick={() => {
          setShowCalendar(!showCalendar);
          setIsActive(true);
        }}
        onChange={handleChange}
        ref={innerRef}
        autoComplete="off"
        placeholder={placeholder ? placeholder : ' '}
        readOnly
      />

      <label
        htmlFor="username"
        className={`absolute top-0 text-sm font-inter bg-white p-3 duration-300 origin-0 rounded-full ${
          showCalendar ? 'z-10 focus-within:text-blue-600' : '-z-1 text-gray1'
        }`}
      >
        {label}
      </label>

      <div
        className={`bg-white shadow-lg border border-black rounded-3 ${
          showCalendar ? 'absolute z-40' : 'hidden '
        }`}
      >
        {serviceLocationId?.id === 2 && (
          <div className="flex items-center justify-between w-full px-4 py-4 bg-gray2">
            <p className="text-black my-2 text-sm font-inter font-semibold">
              Disable Hari Minggu ?
            </p>
            <label
              htmlFor="toogleA"
              className="flex items-center cursor-pointer"
            >
              <div className="relative">
                <input
                  id="toogleA"
                  type="checkbox"
                  className="sr-only"
                  onChange={handleToggleDisableWeek}
                />
                <div className="w-10 h-4 bg-gray-400 rounded-full shadow-inner"></div>
                <div className="dot absolute w-6 h-6 bg-white rounded-full shadow -left-1 -top-1 transition"></div>
              </div>
            </label>
          </div>
        )}

        <Calendar
          value={selectedDay}
          colorPrimary="#0fbcf9"
          onChange={(value) => {
            setSelectedDay(value);
            return fixValue(
              `${value?.year}-${
                value?.month < 10 ? `0${value?.month}` : `${value?.month}`
              }-${value?.day < 10 ? `0${value?.day}` : `${value?.day}`}`
            );
          }}
          shouldHighlightWeekends
          locale={myCustomLocale}
          minimumDate={withDateNow ? utils().getToday() : false}
          disabledDays={disabledDays}
        />
      </div>
    </div>
  );
}
