import { IconCloseRed } from 'assets';
import { LabelStatus } from 'components/atoms';
import React from 'react';
import { formatNumber } from 'utils';

export const CardVaccineTicket = ({ data, onClickRemoveTicket }) => {
  return (
    <div className="bg-gray2 p-5 mb-4">
      <div className="flex justify-between">
        <p className="font-inter font-bold text-black2 text-sm">
          {data.family_member.fullname}
          <LabelStatus
            type={data.queue_status.name}
            title={data.queue_status.name}
            className="font-semibold font-inter text-sm bg-gray2 ml-4"
          />
        </p>

        {/* <img
          src={IconCloseRed}
          alt={IconCloseRed}
          onClick={onClickRemoveTicket}
          className="cursor-pointer"
        /> */}
      </div>

      <p className="font-inter text-sm text-black2 mb-4">
        {data.family_member.age.y < 19 ? 'Remaja' : 'Dewasa'}{' '}
        <span className="text-gray4 text-xl">&bull;</span>{' '}
        {data.family_member.age.y} Tahun
      </p>

      {data.vaccinations?.map((vaksin) => (
        <div className="flex justify-between mb-2" key={vaksin?.id}>
          <p className="font-inter text-sm text-black2">
            {vaksin?.vaccine_types?.map((tipe, i) => tipe?.name).join(', ')} -{' '}
            {vaksin?.vaccine?.brand_name} ke{' '}
            {vaksin?.vaccine_types?.map((tipe, i) =>
              tipe.pivot.period === null ? 0 : tipe.pivot.period
            )}
          </p>
          <p className="font-inter text-sm text-green1">
            {`Rp. ${formatNumber(vaksin.price)}`}
          </p>
        </div>
      ))}
    </div>
  );
};
