import { Modal } from 'components/atoms';
import ButtonPrimary from 'components/atoms/Button/ButtonPrimary';
import { Header } from 'components/atoms/Header/Header';
import { LoadingFullContent } from 'components/atoms/LoadingFullContent/LoadingFullContent';
import { TextContent } from 'components/atoms/Text/TextContent';
import { TitleContent } from 'components/atoms/Text/TitleContent';
import API from 'config/Axios';
import { getOneOrders } from 'config/Redux/action';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { ConfirmOrderPayment } from 'services/ConfirmOrderPayment';
import { FinishTransaction } from 'services/FinishTransaction';
import { CancelTransaction } from 'services/CancelTransaction';
import { formatDateAsc, formatNumber, getPaymentMethod } from 'utils';
import { v4 as uuidv4 } from 'uuid';
import { CardVaccineTicket } from './components/CardVaccineTicket/CardVaccineTicket';
import { CustomerDetailTransaction } from './components/CustomerDetailTransaction/CustomerDetailTransaction';
import { ModalCancelTransaction } from './components/ModalCancelTransaction/ModalCancelTransaction';
import { ModalFinishedTransaction } from './components/ModalFinishedTransaction/ModalFinishedTransaction';
import { PaymentOrderDetail } from './components/PaymentOrderDetail/PaymentOrderDetail';
import { GeneratedPayloadConfirmPayment } from './utils/GeneratedPayloadConfirmPayment';
import ReactHtmlParser from 'react-html-parser';

export default function DetailPesanan(props) {
  const [detailOrder, setDetailOrder] = useState('');
  const [updateStatusPembayaran, setUpdateStatusPembayaran] = useState({
    show: false,
    lokasiVaksin: '',
  });
  const [konfirmasiPembayaran, setKonfirmasiPembayaran] = useState(false);
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [refundID, setRefundID] = useState(null);
  const [isShowBtnFinishedTransaction, setIsShowBtnFinishedTransaction] =
    useState(true);
  const [isShowModalFinished, setIsShowModalFinished] = useState(false);
  const [isShowModalCancelTransaction, setIsShowModalCancelTransaction] =
    useState(false);
  const paymentMethod = getPaymentMethod();

  const { id } = useParams();
  const dispatch = useDispatch();

  const _getDetailOrder = async () => {
    const order = await dispatch(getOneOrders(`/staff/orders/${id}`));
    const refund = paymentMethod?.filter((item) => item.name === 'Refund');
    setRefundID(refund?.[0]?.id);

    const isShowBtnFinish =
      order?.order_status_id !== 2 && order?.order_status_id !== 3;
    setIsShowBtnFinishedTransaction(isShowBtnFinish);

    const additional = paymentMethod?.filter(
      (item) => item.name === 'Additional'
    );

    const addtionalPayment = order?.additionalPaymentChannel;
    const amountMainPaymentChannel = order?.mainPaymentChannel?.amount || 0;

    const tempAmountAdditionalPay =
      order?.total_price - amountMainPaymentChannel;

    const tempAdditionalPayID =
      tempAmountAdditionalPay + amountMainPaymentChannel < order?.total_price ||
      tempAmountAdditionalPay < 0
        ? refund?.[0]?.id
        : additional?.[0].id;

    const amountDifferent =
      order?.total_price - amountMainPaymentChannel !==
      (order?.additionalPaymentChannel?.amount || 0);

    if (amountDifferent && order?.order_status_id === 1) {
      const tempDataDetail = { ...order };
      const tempAdditionalPay = {
        ...tempDataDetail,
        additionalPaymentChannel: {
          id: uuidv4(),
          order_id: order?.id,
          payment_channel_id: 3,
          amount: tempAmountAdditionalPay,
          notes: null,
          payment_method_id: tempAdditionalPayID,
          type: 'additional',
          // payment_channel_status: 0,
          payment_channel: {
            id: 0,
            name: '',
          },
          payment_method: {
            id: tempAdditionalPayID,
            name: '',
          },
        },
      };
      return setDetailOrder(tempAdditionalPay);
    }
    return setDetailOrder(order);
  };

  const onUpdatePaymentStatus = () => {
    setUpdateStatusPembayaran({
      show: true,
      lokasiVaksin: detailOrder.service_location_id,
    });
  };

  const onClickRemoveTicket = () => {
    console.log('remove ticket clicked');
  };

  const onSelectOptions = (idOption) => {
    const tempDataAdditional = { ...detailOrder?.additionalPaymentChannel };
    tempDataAdditional.payment_channel_id = idOption;

    setDetailOrder({
      ...detailOrder,
      additionalPaymentChannel: tempDataAdditional,
    });
  };

  const onClickFinishedVaccination = () => {
    setIsLoading(true);
    const payloadConfirmPayment = GeneratedPayloadConfirmPayment({
      data: detailOrder,
    });

    const confirmOrderPayment = ConfirmOrderPayment(payloadConfirmPayment);
    const finishTransaction = FinishTransaction(detailOrder?.id);

    Promise.all([confirmOrderPayment, finishTransaction])
      .then(() => setIsShowModalFinished(true))
      .catch((err) => err)
      .finally(() => {
        _getDetailOrder();
        setIsLoading(false);
      });
  };

  const [htmlTes, setHtmlTes] = useState('');

  const onPrintInvoice = async () =>
    window.open(`/print-invoice/${detailOrder.id}`, '_blank');

  const onCloseModalFinish = () => setIsShowModalFinished(false);
  const onClickCancelTransaction = () => {
    if (detailOrder?.payment_status_id === 3)
      return onShowModalCancelTransction();

    // eslint-disable-next-line no-restricted-globals
    const isCancelTransaction = confirm(
      'Apakah anda yakin ingan membatalkan transaksi ?'
    );

    if (isCancelTransaction) {
      CancelTransaction(detailOrder?.id)
        .then(() => {
          _getDetailOrder();
        })
        .catch(() => alert('Gagal Membatalkan Transaksi'))
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const onShowModalCancelTransction = () =>
    setIsShowModalCancelTransaction(true);
  const onCloseModalCancelTransaction = () =>
    setIsShowModalCancelTransaction(false);

  useEffect(() => {
    _getDetailOrder();
  }, []);

  if (detailOrder === '') {
    return <LoadingFullContent />;
  }

  return (
    <div className="flex flex-col bg-white justify-between pb-4 h-screen  overflow-y-scroll">
      <div className="bg-white pt-11 px-16">
        <Header
          title="Detail Transaksi"
          subTitle={`a.n ${detailOrder?.contact_person_name || ''} - ${
            detailOrder?.order_number
          }`}
          data={detailOrder}
          status={detailOrder?.payment_status?.name}
          onChangeStatus={onUpdatePaymentStatus}
          createdAt={detailOrder.created_at}
          withBtnCancelTransction
          onClickCancelTransction={onClickCancelTransaction}
        />

        <div className="md:block lg:flex mt-4 min-w-full pb-4 ">
          <div className="w-full lg:w-6/12">
            <CustomerDetailTransaction data={detailOrder} />

            <div className="mb-11">
              <TitleContent text="Tempat Pemberian Vaksin" />

              <div className="">
                <div className="flex gap-2">
                  <TextContent
                    text={detailOrder?.service_location?.name}
                    className="mb-1"
                  />
                  {detailOrder?.homecare_service_location?.name && (
                    <TextContent
                      text={`- ${detailOrder?.homecare_service_location?.name}`}
                    />
                  )}
                </div>

                <div className="flex gap-2 flex-col justify-between">
                  <TextContent text={`${detailOrder?.address?.full_address}`} />
                  <TextContent
                    text={`${detailOrder?.note}`}
                    isSecondary
                    className="capitalize"
                  />

                  {/* {detailOrder.service_location_id !== 1 &&
                    detailOrder?.service_location?.address !== null && (
                      <Button
                        title="> Detail Alamat"
                        className="font-inter font-normal text-sm text-gray1 whitespace-nowrap"
                      />
                    )} */}
                </div>
              </div>
            </div>

            <div className="">
              <div className="mb-11">
                <TitleContent text="Tanggal Vaksinasi" />
                <TextContent text={formatDateAsc(detailOrder.date_plan)} />
              </div>

              <TitleContent text=" Tiket Vaksin" />
              {detailOrder.queues?.map((item, i) => (
                <CardVaccineTicket
                  key={item.id}
                  data={item}
                  onClickRemoveTicket={onClickRemoveTicket}
                />
              ))}

              {/* <div className="mb-8">
                <p className="font-inter font-bold text-black2 mb-2">
                  Vaksinator
                </p>
                <p className="font-inter font-normal text-sm text-black2">
                  {detailOrder.doctor?.doctor_category.name}
                  <span className="font-inter font-semibold text-black2">
                    &nbsp; - &nbsp;
                    {detailOrder.doctor?.doctor_category.service_price === 0
                      ? 'Gratis'
                      : `Rp. ${detailOrder.doctor?.doctor_category.service_price}`}
                  </span>
                </p>
              </div> */}

              {/* <div className="mb-12">
                <p className="font-inter font-bold text-black2 mb-2">
                  Metode Pembayaran
                </p>

                <p className="font-inter font-normal text-sm text-black2">
                  {detailOrder?.payment_method?.name || '-'} */}

              {/* COD */}
              {/* {detailOrder?.payment_method_id === 1 && (
                    <Button
                      title="&nbsp; Update Status Pembayaran"
                      className="text-blue1"
                      onClick={() => onUpdatePaymentStatus()}
                    />
                  )} */}

              {/* Transfer Bank Check Manual */}
              {/* {detailOrder?.payment_method_id === 3 && (
                    <Button
                      title="&nbsp; Konfirmasi Pembayaran"
                      className="text-blue1"
                      onClick={() => setKonfirmasiPembayaran(true)}
                    />
                  )} */}
              {/* </p>
              </div> */}
            </div>
          </div>

          <div className="w-full lg:w-1/12 flex justify-center" />

          <div className="w-full lg:w-6/12">
            <PaymentOrderDetail data={detailOrder} onSelect={onSelectOptions} />
          </div>
        </div>
      </div>

      <div className="pt-11 px-16 flex justify-between items-center">
        {/* <Button
          isPrimaryOutline
          isBtnOutlineSecondary
          title="Kembali"
          className="rounded-3"
          onClick={() => props.history.goBack()}
        /> */}

        <div className="flex justify-between w-full border-t py-4 border-gray3">
          <p className="font-bold font-inter text-xl flex items-center mr-9">
            Total Biaya :{' '}
            <span className="font-bold font-inter text-xl ml-4 text-green2">
              {`Rp. ${formatNumber(detailOrder?.total_price)}`}
            </span>
          </p>

          {isShowBtnFinishedTransaction && (
            <ButtonPrimary
              onClick={onClickFinishedVaccination}
              isDisabled={isLoading}
              title={isLoading ? 'Loading...' : 'Selesaikan Transaksi'}
            />
          )}

          {!isShowBtnFinishedTransaction &&
            detailOrder?.order_status_id !== 3 && (
              <ButtonPrimary
                onClick={onPrintInvoice}
                isDisabled={isLoading}
                title={isLoading ? 'Loading...' : 'Print Invoice'}
              />
            )}

          {/* <LabelStatus
            withOutline
            type={detailOrder.payment_status.name}
            title={detailOrder.payment_status.name}
          /> */}
        </div>
      </div>

      <ModalCancelTransaction
        isOpen={isShowModalCancelTransaction}
        data={detailOrder}
        onClose={onCloseModalCancelTransaction}
        onSuccessCancel={_getDetailOrder}
      />

      {isShowModalFinished && (
        <ModalFinishedTransaction
          orderNumber={detailOrder?.order_number}
          onClick={onCloseModalFinish}
          isOpen={isShowModalFinished}
        />
      )}

      {updateStatusPembayaran.show && (
        <Modal
          type="update-status-pembayaran"
          idPasien={detailOrder.queues[0].order_id}
          closeModal={() => setUpdateStatusPembayaran(!updateStatusPembayaran)}
          value={(value) => {}}
          statusPembayaran={() => _getDetailOrder()}
        />
      )}

      {konfirmasiPembayaran && (
        <Modal
          type="konfirmasi-pembayaran"
          idPasien={detailOrder.id}
          closeModal={() => setKonfirmasiPembayaran(false)}
          // statusPembayaran={() => _getDetailOrder()}
        />
      )}
    </div>
  );
}
