import API from 'config/Axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import ReactHTMLParser from 'react-html-parser';

export const PrintInvoice = () => {
  const params = useParams();
  const { id } = params;

  const [htmlInvoice, setHtmlInvoice] = useState('');

  useEffect(() => {
    API.get(`${process.env.REACT_APP_FILE_URL}/receipt/${id}`).then((res) =>
      setHtmlInvoice(res.data)
    );
  }, [id]);

  useEffect(() => {
    const timeId = setTimeout(() => {
      if (htmlInvoice !== '') {
        window.print();
      }
    }, 1000);
    return () => {
      clearTimeout(timeId);
    };
  }, [htmlInvoice]);

  if (!htmlInvoice) return <p>Loading..</p>;

  return ReactHTMLParser(htmlInvoice);
};
