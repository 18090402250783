import {
  Autocomplete,
  Circle,
  GoogleMap,
  Marker,
  useLoadScript,
} from '@react-google-maps/api';
import {
  IconLoading,
  IconMap,
  IconCloseTambahAntrian,
  IconMarkerMaps,
  IconClose,
} from 'assets';
import ButtonIcon from 'components/atoms/Button/ButtonIcon';
import API from 'config/Axios';
import {
  checkTicket,
  getKategoriDokter,
  getLokasiPemberianVaksin,
  getNamaPasien,
  getRegistrationCount,
  getStatusAntrian,
  getStatusPembayaran,
  tambahAntrian,
  getShiftId,
  getAllAddress,
  getAllPaymentMethod,
} from 'config/Redux/action';
import { set } from 'js-cookie';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { formatNumber, getPaymentChannel, setPaymentChannel } from 'utils';
import {
  AutoComplete,
  Button,
  Error,
  Input,
  JenisVaksinAuto,
  Modal,
} from '../../atoms';
import ButtonNew from 'components/atoms/Button/Button';

const libraries = ['places', 'geometry'];

export default function TambahAntrian(props) {
  const [dataName, setDataName] = useState([]);
  const [checkDistance, setCheckDistance] = useState(false);
  const [lokasiVaksin, setLokasiVaksin] = useState([]);
  const [fixLokasi, setFixLokasi] = useState({
    id: '',
    title: '',
  });

  // START : Config Maps
  const [fixAlamat, setFixAlamat] = useState('');
  const [center, setCenter] = useState({ lat: -5.156742, lng: 119.415056 });
  const [autoComplete, setAutoComplete] = useState(null);
  const [position, setPosition] = useState({
    lat: -5.156742,
    lng: 119.415056,
  });
  const [showMaps, setShowMaps] = useState(false);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: 'AIzaSyB_73hYRCjyR5yeJXgQHcYjun6JGYGpM8E',
    libraries,
    language: 'id',
  });

  const [map, setMap] = React.useState(null);

  const onLoad = useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds();
    map.fitBounds(bounds);
    setMap(map);
  }, []);

  const onUnmount = useCallback(function callback(map) {
    setMap(null);
  }, []);

  const onLoadAutoComplete = (autocomplete) => {
    setAutoComplete(autocomplete);
  };

  const onPlaceChangedAutoComplete = () => {
    if (autoComplete !== null) {
      const tes = autoComplete.getPlace();
      // let testingDistance =
      //   window.google.maps.geometry.spherical.computeDistanceBetween(
      //     new window.google.maps.LatLng(-5.156742, 119.415056),
      //     new window.google.maps.LatLng(
      //       tes.geometry.location.lat(),
      //       tes.geometry.location.lng()
      //     )
      //   );

      // if (testingDistance > 2000) {
      //   return setCheckDistance(true);
      // }

      setFixAlamat(tes.formatted_address);
      setPosition({
        ...position,
        lat: tes.geometry.location.lat(),
        lng: tes.geometry.location.lng(),
      });

      setCenter({
        ...center,
        lat: tes.geometry.location.lat(),
        lng: tes.geometry.location.lng(),
      });
    } else {
      console.log('Autocomplete is not loaded yet!');
    }
  };

  const lokasiBaru = (latlng) => {
    // let testingDistance =
    //   window.google.maps.geometry.spherical.computeDistanceBetween(
    //     new window.google.maps.LatLng(-5.156742, 119.415056),
    //     new window.google.maps.LatLng(latlng.lat, latlng.lng)
    //   );

    // if (testingDistance > 2000) {
    //   return setCheckDistance(true);
    // }

    var geocoder = (geocoder = new window.google.maps.Geocoder());
    return geocoder.geocode({ latLng: latlng }, function (results, status) {
      if (status == window.google.maps.GeocoderStatus.OK) {
        if (results[1]) {
          setFixAlamat(results[1].formatted_address);
        }
      }
    });
  };

  const handleDrag = (drag) => {
    setPosition({
      ...position,
      lat: drag.latLng.lat(),
      lng: drag.latLng.lng(),
    });
  };

  const currentLocation = () => {
    navigator.geolocation.getCurrentPosition((position) => {
      setCenter({
        ...center,
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      });
    });
  };

  // END: Config Maps

  const [kategoriDokter, setKategoriDokter] = useState([]);
  const [fixKategoriDokter, setFixKategoriDokter] = useState({
    id: '',
    title: '',
  });
  const [vaksinator, SetVaksinator] = useState({
    id: '',
    title: '',
  });

  const [statusAntrian, setStatusAntrian] = useState([]);
  const [fixStatusAntrian, setFixStatusAntrian] = useState({
    id: '',
    title: '',
  });

  const [statusPembayaran, setStatusPembayaran] = useState([]);
  const [fixStatusPembayaran, setFixStatusPembayaran] = useState({
    id: '',
    title: '',
  });
  const [fixPaymentChannel, setFixPaymentChannel] = useState({
    id: '',
    title: '',
  });

  const allPaymentChannel = getPaymentChannel();

  const [inputNama, setInputNama] = useState({
    isLoading: false,
    listNama: [],
  });

  const [tglVaksin, setTglVaksin] = useState('');
  const [noHp, setNoHp] = useState('');

  const [deleteId, setDeleteId] = useState('');

  const INITIAL_VALUE_PENERIMA_VAKSIN = {
    id: `input-${new Date().getTime()}`,
    family_id: '',
    family_name: '',
    vaccine_recipients: [
      {
        id: `jv-${new Date().getTime()}`,
        vaccine_name: '',
        vaccine_type_name: '',
        price: '',
        vaccine_ids: '',
        vaccine_type_ids: '',
      },
    ],
  };

  const [penerimaVaksin, setPenerimaVaksin] = useState([
    INITIAL_VALUE_PENERIMA_VAKSIN,
  ]);

  const [registrationCount, setRegistrationCount] = useState({});

  const [showModal, setShowModal] = useState({
    show: false,
    idInput: '',
    data: '',
  });

  const [fixVasin, setFixVasin] = useState('');
  const [showLoading, setShowLoading] = useState(false);
  const [response, setResponse] = useState('');
  const [fixHarga, setFixHarga] = useState([]);
  const [ticketAvailable, setTicketAvailable] = useState(false);
  const [shiftId, setShiftId] = useState({
    id: '',
    title: '',
  });
  const [listShiftId, setListShiftId] = useState([]);
  const [dataPendaftarOrder, setDataPendaftarOrder] = useState({
    id: 0,
    name: '',
  });
  const [noteAlamat, setNoteAlamat] = useState('');
  const [listAlamat, setListAlamat] = useState([]);
  const [loadingAutocomplate, setLoadingAutocomplate] = useState(false);

  const isLoading = useSelector((state) => state.isLoading);

  const vaksin = (data) => {
    setFixVasin([...fixVasin, data]);
  };

  const dispatch = useDispatch();

  const cekUserInput = async (data) => {
    if (data.length > 2) {
      // setLoadingAutocomplate(true);
      setInputNama({ ...inputNama, isLoading: true });
      const input = await dispatch(
        getNamaPasien(`/staff/family-members/search?keyword=${data}`)
      );
      // setInputNama(input);

      setInputNama({ isLoading: false, listNama: input });
    } else {
      // return setLoadingAutocomplate(false);
      return setInputNama({ isLoading: false, listNama: [] });
    }
  };

  const getAntrianCount = async (tanggal) => {
    const _getRegistationCount = await dispatch(
      getRegistrationCount('/staff/queues/registration-count?', tanggal, 1)
    );

    setRegistrationCount(_getRegistationCount);
  };

  const handleClick = async () => {
    const vaccine_recipients = [];

    const dataUserTambahAntrian = {
      date: tglVaksin,
      shift_id: 1,
      registration_origin_id: 3,
      service_location_id: fixLokasi,
      doctor_id: vaksinator,
      phone_number: noHp,
      address_id: 1,
      queue_status_id: fixStatusAntrian,
      payment_status_id: fixStatusPembayaran,
      vaccine_recipients: penerimaVaksin,
    };

    if (
      !dataUserTambahAntrian.date ||
      !dataUserTambahAntrian.shift_id ||
      !dataUserTambahAntrian.registration_origin_id ||
      !dataUserTambahAntrian.service_location_id ||
      !dataUserTambahAntrian.doctor_id ||
      !dataUserTambahAntrian.phone_number ||
      !dataUserTambahAntrian.address_id ||
      !dataUserTambahAntrian.queue_status_id ||
      !dataUserTambahAntrian.payment_status_id ||
      !dataUserTambahAntrian.vaccine_recipients
    ) {
      return alert('Masih ada yang kosong');
    }

    for (let xx = 0; xx < penerimaVaksin.length; xx++) {
      let tt = [];

      const element = penerimaVaksin[xx];
      vaccine_recipients.push({
        family_member_id: element?.family_member_id,
        vaccine_ids: element?.vaccine_recipients?.map(
          (item) => item.vaccine_ids
        ),
        vaccine_type_ids: element?.vaccine_recipients?.map((item, i) => {
          return item?.vaccine_type_ids?.map((j) => tt.push(j));
        }),
      });

      vaccine_recipients[xx].vaccine_type_ids = tt;

      dataUserTambahAntrian.vaccine_recipients = vaccine_recipients;
    }

    vaccine_recipients.map((item, i) => {
      const tes = [...item.vaccine_type_ids];
      var filterVaksinTypeId = tes.filter((elem, index, self) => {
        return index === self.indexOf(elem);
      });

      item.vaccine_type_ids = filterVaksinTypeId;
    });

    setShowLoading(true);
    const send = await dispatch(
      tambahAntrian('/staff/queues', dataUserTambahAntrian)
    );
    setResponse(send);
  };

  const [addressId, setAddressId] = useState(0);
  const [fixDataAlamat, setFixDataAlamat] = useState('');
  const [tunggu, setTunggu] = useState(false);

  const { register, errors, handleSubmit, reset } = useForm();
  const [isReset, setIsReset] = useState(false);
  const [fixMetodePembayaran, setFixMetodePembayaran] = useState([]);
  const [IdMetodePembayaran, setIdMetodePembayaran] = useState({
    id: '',
    title: '',
  });
  const [isDisabledCancel, setIsDisabledCancel] = useState(false);
  const [isShowPaymentChannel, setIsShowPaymentChannel] = useState(false);

  const onResetAutoComplete = () => setIsReset(!isReset);

  const onClickCancel = (e) => {
    setIsDisabledCancel(true);
    e.preventDefault();
    setFixAlamat('');
    setFixDataAlamat('');
    onResetAutoComplete();
    setInputNama({ isLoading: false, listNama: [] });
    setFixKategoriDokter({
      id: '',
      title: '',
    });
    setFixHarga([]);
    setFixStatusAntrian({
      id: '',
      title: '',
    });
    setPenerimaVaksin([INITIAL_VALUE_PENERIMA_VAKSIN]);
    setFixStatusPembayaran({
      id: '',
      title: '',
    });
    setIdMetodePembayaran({
      id: '',
      title: '',
    });
    setDataPendaftarOrder({
      id: 0,
      name: '',
    });
    setAddressId(0);
    setNoHp('');
    SetVaksinator({
      id: '',
      title: '',
    });
    setTglVaksin('');
    setShiftId({
      id: '',
      title: '',
    });
    setFixLokasi({
      id: '',
      title: '',
    });
    reset();

    setTimeout(() => {
      setIsDisabledCancel(false);
    }, 2000);
  };

  const onSubmit = async (data) => {
    setShowLoading(true);
    setTunggu(true);
    const vaccine_recipients = [];

    const dataUserTambahAntrian = {
      date_plan: tglVaksin,
      shift_id: shiftId.id,
      registration_origin_id: 3,
      service_location_id: fixLokasi.id,
      doctor_id: vaksinator.id,
      phone_number: noHp,
      address_id: addressId,
      note: fixDataAlamat?.note || '',
      family_member_id: dataPendaftarOrder.id,
      contact_person_name: dataPendaftarOrder.name,
      // queue_status_id: fixStatusAntrian,
      payment_method_id: IdMetodePembayaran.id,
      payment_status_id: fixStatusPembayaran.id,
      vaccine_recipients: penerimaVaksin,
      ...(isShowPaymentChannel
        ? { payment_channel_id: fixPaymentChannel.id }
        : {}),
    };

    if (
      !dataUserTambahAntrian.date_plan ||
      !dataUserTambahAntrian.shift_id ||
      !dataUserTambahAntrian.registration_origin_id ||
      !dataUserTambahAntrian.service_location_id ||
      !dataUserTambahAntrian.doctor_id ||
      !dataUserTambahAntrian.phone_number ||
      // !dataUserTambahAntrian.address_id ||
      // !dataUserTambahAntrian.queue_status_id ||
      !dataUserTambahAntrian.payment_method_id ||
      !dataUserTambahAntrian.payment_status_id ||
      !dataUserTambahAntrian.vaccine_recipients
    ) {
      return alert('masih ada yang kosong');
    }

    for (let xx = 0; xx < penerimaVaksin.length; xx++) {
      let tt = [];

      const element = penerimaVaksin[xx];
      vaccine_recipients.push({
        family_member_id: element?.family_member_id,
        vaccine_ids: element?.vaccine_recipients?.map(
          (item) => item.vaccine_ids
        ),
        vaccine_type_ids: element?.vaccine_recipients?.map((item, i) => {
          return item?.vaccine_type_ids?.map((j) => tt.push(j));
        }),
      });

      vaccine_recipients[xx].vaccine_type_ids = tt;

      dataUserTambahAntrian.vaccine_recipients = vaccine_recipients;
    }

    vaccine_recipients.map((item, i) => {
      const tes = [...item.vaccine_type_ids];
      var filterVaksinTypeId = tes.filter((elem, index, self) => {
        return index === self.indexOf(elem);
      });

      item.vaccine_type_ids = filterVaksinTypeId;
    });

    if (dataUserTambahAntrian.service_location_id === 2) {
      await API.post('/staff/addresses', fixDataAlamat)
        .then((res) => {
          return (dataUserTambahAntrian.address_id = res.data.address.id);
        })
        .catch((err) => err);
    }

    const send = await dispatch(
      tambahAntrian('/staff/orders', dataUserTambahAntrian)
    );

    setResponse(send);
    setTunggu(false);
  };

  useEffect(() => {
    const _getData = async () => {
      const tempatVaksinasi = await dispatch(
        getLokasiPemberianVaksin('/staff/service-locations')
      );
      setLokasiVaksin(tempatVaksinasi);

      const kategoriDokter = await dispatch(
        getKategoriDokter('/staff/doctors/categories ')
      );
      setKategoriDokter(kategoriDokter);

      const listStatusAntrian = await dispatch(
        getStatusAntrian('/staff/queue-statuses', fixLokasi)
      );
      setStatusAntrian(listStatusAntrian.data.queueStatuses);

      const listStatusPembayaran = await dispatch(
        getStatusPembayaran('/staff/payment-statuses')
      );
      setStatusPembayaran(listStatusPembayaran);

      const shift = await dispatch(getShiftId('/staff/shifts'));
      setListShiftId(shift);

      const allAddress = await dispatch(
        getAllAddress(`/staff/addresses`, penerimaVaksin[0].family_id)
      );
      setListAlamat(allAddress);

      const paymentMethod = await dispatch(
        getAllPaymentMethod('/staff/payments/methods')
      );
      setFixMetodePembayaran(paymentMethod);
    };
    _getData();
    currentLocation();

    return () => {
      const _getAddress = async () => {
        const allAddress = await dispatch(
          getAllAddress(`/staff/addresses`, penerimaVaksin[0].family_id)
        );
        setListAlamat(allAddress);
      };
      _getAddress();
    };
  }, [fixLokasi]);

  useEffect(() => {
    const tempIsShowPaymentChannel =
      fixStatusPembayaran?.id ===
      statusPembayaran.filter((item) => item.name === 'Sudah Bayar')?.[0]?.id;
    setIsShowPaymentChannel(tempIsShowPaymentChannel);
  }, [fixStatusPembayaran, statusPembayaran]);

  // if (!errors) {
  //   return <p>Loading</p>;
  // }
  // if (loadError) return "Error loading maps";
  // if (!isLoaded) return "Loading maps";

  if (loadError || !isLoaded || !errors) {
    return (
      <div className="h-screen overflow-hidden flex flex-col  pt-11 pb-7 px-9 bg-white">
        <div className="max-h-screen flex-1 overflow-y-scroll scrollbar-none pb-9 px-1">
          <p>Loading</p>
        </div>
      </div>
    );
  }

  var circle = new window.google.maps.Circle({
    center: new window.google.maps.LatLng(-5.156742, 119.415056),
    radius: 2000,
  });

  return (
    <form>
      <div className="h-screen overflow-hidden flex flex-col  pt-11 pb-7 px-9 bg-white">
        <div className="max-h-screen flex-1 overflow-y-scroll scrollbar-none pb-9 px-1">
          {/* START : Header Content Tambah Antrian */}
          <div className="content border-b-2 pb-8 border-gray2">
            <h2 className=" font-inter font-bold text-3xl text-black">
              Buat Antrian
            </h2>
          </div>
          {/* END : Header Content Tambah Antrian  */}

          <div className="md:block lg:flex mt-4 min-w-full pb-4">
            <div className="w-full lg:w-6/12">
              <div className="font-inter pb-5 mb-6">
                <h2 className="font-bold text-lg text-black mb-4">
                  Nama Pendaftar
                </h2>
                <AutoComplete
                  // id={}
                  isReset={isReset}
                  isloading={inputNama.isLoading}
                  options={inputNama.listNama}
                  label="Nama Pasien"
                  className="flex-1"
                  setvalue={async (value) => {
                    setDataPendaftarOrder({
                      id: value.id,
                      name: value.fullname,
                    });
                  }}
                  userInput={(data) => cekUserInput(data)}
                  name={`nama-pendaftar`}
                  ref={register({
                    required: true,
                  })}
                  placeholder="Masukkan Nama Pendaftar"
                  // available={(value) => setTicketAvailable(value)}
                />

                <h2 className="font-bold text-lg text-black mb-4 mt-8">
                  Penerima Vaksin
                </h2>

                {penerimaVaksin.map((penerima, i) => {
                  let k = i;
                  return (
                    <div key={i}>
                      <div className="flex mb-5">
                        {penerimaVaksin.length > 1 && (
                          <ButtonIcon
                            icon={IconCloseTambahAntrian}
                            className="text-xl px-1 -ml-3 mr-2 rounded-3  font-inter  text-black font-extralight -mt-4"
                            onClick={async (e) => {
                              const newDataPenerima = [...penerimaVaksin];

                              const deleteIds = penerima.id;

                              let fixData = newDataPenerima.filter(
                                (item) => item.id !== deleteIds
                              );

                              setPenerimaVaksin(fixData);
                            }}
                          />
                        )}
                        <AutoComplete
                          id={penerima.id}
                          isReset={isReset}
                          isloading={inputNama.isLoading}
                          options={inputNama.listNama}
                          label="Nama Pasien"
                          className="flex-1"
                          setvalue={async (value) => {
                            penerimaVaksin[i].family_name = value.fullname;
                            penerimaVaksin[i].family_member_id = value.id;
                            penerimaVaksin[i].family_id = value.family_id;

                            try {
                              await dispatch(
                                checkTicket(
                                  `/staff/family-members/ticket-availability/${value.id}`
                                )
                              );
                            } catch (error) {
                              setTicketAvailable(true);
                            }
                          }}
                          userInput={(data) => cekUserInput(data)}
                          name={`pasien.${i}`}
                          ref={register({
                            required: true,
                          })}
                          placeholder="Masukkan Nama Pasien"
                          // available={(value) => setTicketAvailable(value)}
                        />

                        {ticketAvailable && (
                          <Modal
                            type="ready-ticket"
                            closeModal={() => {
                              return setTicketAvailable(false);
                            }}
                          />
                        )}
                      </div>

                      {penerima.vaccine_recipients?.map((jenis, index) => (
                        <div
                          className="flex items-stretch justify-center mb-5"
                          key={index}
                        >
                          {penerimaVaksin.length > 1 && (
                            <div className="text-lg -ml-3.5 px-4 mr-2 text-white">
                              X
                            </div>
                          )}
                          <Input
                            id={penerima.vaccine_recipients[index].id}
                            withOutline
                            label="Jenis Vaksin"
                            type="text"
                            name="jenis-vaksin"
                            className="flex-1"
                            value={
                              jenis.vaccine_name === ''
                                ? `${jenis.vaccine_type_name}`
                                : `${jenis.vaccine_name} - Rp.${jenis.price}`
                            }
                            readOnly
                            onClick={() =>
                              setShowModal({
                                show: true,
                                idInput: penerima.vaccine_recipients[index].id,
                                data: penerima,
                              })
                            }
                            ref={register({
                              required: true,
                            })}
                            placeholder="Pilih Jenis Vaksin"
                          />

                          {penerima.vaccine_recipients.length > 1 && (
                            <Button
                              title="X"
                              className="text-2xl px-4 border bg-red1 border-red1 rounded-3  font-inter  text-white font-extralight ml-4"
                              onClick={async () => {
                                const newDataPenerima = [...penerimaVaksin];

                                const deleteIds =
                                  penerima.vaccine_recipients[index].id;

                                let fixData = newDataPenerima.map((item, o) => {
                                  const tes = item.vaccine_recipients.filter(
                                    (data) => data.id !== deleteIds
                                  );

                                  item.vaccine_recipients = tes;
                                  return item;
                                });

                                setPenerimaVaksin(fixData);
                              }}
                            />
                          )}

                          {showModal.show &&
                            showModal.idInput ===
                              penerima.vaccine_recipients[index].id && (
                              <Modal
                                isNextVaksin={false}
                                modalId={showModal.idInput}
                                data={showModal.data}
                                type="jenis-vaksin"
                                closeModal={() => setShowModal(!showModal)}
                                value={(data, modalId) => {
                                  let hargaVaksin = [];
                                  penerimaVaksin.map((item, i) => {
                                    item.vaccine_recipients.map(
                                      (data, index) => {
                                        return data.price
                                          ? hargaVaksin.push(data.price)
                                          : null;
                                        // setFixHarga([...fixHarga, data.price]);
                                      }
                                    );
                                  });

                                  setFixHarga(hargaVaksin);
                                }}
                              />
                            )}
                        </div>
                      ))}

                      <div className="w-full flex justify-end">
                        <Button
                          title="+ Tambah Jenis Vaksin"
                          className="font-inter font-medium text-blue1 text-sm rounded-3 text-right"
                          onClick={() => {
                            const newArr = [...penerimaVaksin];
                            const newData = {
                              id: `jv-${new Date().getTime()}`,
                              vaccine_name: '',
                              vaccine_type_name: '',
                              price: '',
                              vaccine_ids: '',
                              vaccine_type_ids: '',
                            };

                            newArr[i].vaccine_recipients.push(newData);
                            setPenerimaVaksin(newArr);
                          }}
                        />
                      </div>

                      <div className="my-5 border-b-2 border-gray2"></div>
                    </div>
                  );
                })}
              </div>

              <div className="flex justify-center ">
                <Button
                  title="+ Tambah Penerima Vaksin"
                  className="font-inter font-bold text-sm  text-blue1 rounded-3 ml-12 "
                  onClick={() => {
                    const newArr = [...penerimaVaksin];
                    const newData = {
                      id: `input-${new Date().getTime()}`,
                      family_id: '',
                      family_name: '',
                      vaccine_recipients: [
                        {
                          id: `jv-${new Date().getTime()}`,
                          vaccine_name: '',
                          vaccine_type_name: '',
                          price: '',
                          vaccine_ids: '',
                          vaccine_type_ids: '',
                        },
                      ],
                    };
                    setPenerimaVaksin([...newArr, newData]);
                  }}
                />
              </div>
            </div>

            <div className="w-full lg:w-1/12"></div>

            <div className="w-full lg:w-6/12 md:mt-10 lg:my-0">
              <h2 className="font-inter font-bold text-lg text-black mb-4">
                Jadwal Vaksin
              </h2>

              <Input
                withDropdown
                label="Tempat Pemberian Vaksin"
                type="text"
                name="lokasi-vaksin"
                className="mb-5"
                options={lokasiVaksin}
                value={fixLokasi.title}
                fixValue={(value) =>
                  setFixLokasi({ id: value.id, title: value.name })
                }
                ref={register({
                  required: true,
                })}
                placeholder="Pilih Tempat Vaksinasi"
                onChange={() => setShiftId('')}
              />

              {fixLokasi.id === 2 && (
                <>
                  {showMaps && (
                    <div className="absolute inset-0 bg-black bg-opacity-60 z-20 flex flex-col justify-center items-center">
                      <div className="bg-white p-4 rounded-10">
                        <div className="flex justify-end mb-5">
                          <Button
                            type="button-icon"
                            icon={IconClose}
                            alt="close-btn"
                            onClick={(e) => {
                              e.preventDefault();
                              setShowMaps(false);
                            }}
                          />
                        </div>
                        <GoogleMap
                          id="searchbox-example"
                          // onLoad={onLoad}
                          // onUnmount={onUnmount}
                          mapContainerStyle={{
                            width: 400,
                            height: 400,
                            display: 'block',
                          }}
                          zoom={17}
                          center={{ lat: -5.156742, lng: 119.415056 }}
                          // center={position}
                          options={{
                            disableDefaultUI: true,
                            zoomControl: true,
                            // restriction: {
                            //   latLngBounds: {
                            //     north: -34.36,
                            //     south: -47.35,
                            //     west: 166.28,
                            //     east: -175.81,
                            //   },
                            //   strictBounds: false,
                            // },
                          }}
                        >
                          <Marker
                            position={position}
                            visible={
                              position.lat === 0 || position.lng === 0
                                ? false
                                : true
                            }
                            draggable
                            onDrag={(drag) => handleDrag(drag)}
                            onDragEnd={(drag) => lokasiBaru(position)}
                            icon={IconMarkerMaps}
                          />

                          {/* <Circle
                            center={{ lat: -5.156742, lng: 119.415056 }}
                            // required
                            options={{
                              strokeColor: '#20A614',
                              strokeOpacity: 0.8,
                              strokeWeight: 2,
                              fillColor: '#20A614',
                              fillOpacity: 0.35,
                              clickable: false,
                              draggable: false,
                              editable: false,
                              visible: true,
                              // radius: 2000,
                              zIndex: 1,
                            }}
                            radius={2000}
                          /> */}
                        </GoogleMap>
                        <div className="flex justify-center mt-5">
                          <Button
                            title="Simpan"
                            isPrimary
                            className="rounded-3"
                            onClick={async (e) => {
                              e.preventDefault();

                              // let testingDistance =
                              //   window.google.maps.geometry.spherical.computeDistanceBetween(
                              //     new window.google.maps.LatLng(
                              //       -5.156742,
                              //       119.415056
                              //     ),
                              //     new window.google.maps.LatLng(
                              //       position.lat,
                              //       position.lng
                              //     )
                              //   );

                              // if (testingDistance > 2000) {
                              //   return setCheckDistance(true);
                              // }

                              const data = {};
                              data.family_id = penerimaVaksin[0].family_id;
                              data.full_address = fixAlamat;
                              data.latitude = position.lat;
                              data.longitude = position.lng;

                              setFixDataAlamat(data);
                              setShowMaps(false);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  <Autocomplete
                    onLoad={(a) => onLoadAutoComplete(a)}
                    onPlaceChanged={(a) => onPlaceChangedAutoComplete(a)}
                    restrictions={{ country: 'id' }}
                    options={{
                      restriction: {
                        strictBounds: true,
                      },
                    }}
                    bounds={circle.getBounds()}
                  >
                    <div className="">
                      <div
                        className={`flex items-stretch justify-center mt-5 ${
                          listAlamat.length === 0 || fixAlamat !== ''
                            ? 'mb-5'
                            : 'mb-1'
                        }`}
                      >
                        <Input
                          withOutline
                          label="Alamat"
                          name="alamat"
                          type="text"
                          placeholder="Alamat"
                          className={`flex-1 mr-5 `}
                          value={fixAlamat}
                          onChange={(e) => setFixAlamat(e.target.value)}
                        />

                        <Button
                          type="button-icon"
                          icon={IconMap}
                          onClick={(e) => {
                            e.preventDefault();
                            setShowMaps(true);
                          }}
                          className="px-4 border bg-blue1 border-blue1 rounded-3 font-inter font-bold text-blue1"
                        />
                      </div>

                      {listAlamat.length !== 0 && fixAlamat === '' && (
                        <div className="w-full max-h-52 bg-white overflow-y-scroll mb-6">
                          <ul className="font-inter cursor-pointer text-sm ">
                            {listAlamat.map((list, i) => (
                              <li
                                key={i}
                                className="flex items-center justify-between border border-gray2 rounded-3 px-4 py-3 hover:bg-blue2 focus-within:bg-blue1"
                                onClick={() => {
                                  setFixAlamat(list.full_address);
                                  return setAddressId(list.id);
                                }}
                              >
                                {list.full_address}
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </div>
                  </Autocomplete>

                  {checkDistance && (
                    <div className="absolute inset-0 bg-black bg-opacity-30 z-20 flex items-center justify-center">
                      <div className="bg-white p-8 rounded-3 flex items-center flex-col">
                        <div className="flex justify-center mb-6">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="87"
                            height="87"
                            fill="none"
                            viewBox="0 0 87 87"
                          >
                            <path
                              fill="#CC2727"
                              d="M46.82 44l8.6-8.58a2.008 2.008 0 10-2.84-2.84L44 41.18l-8.58-8.6a2.008 2.008 0 10-2.84 2.84l8.6 8.58-8.6 8.58a1.999 1.999 0 000 2.84 2.002 2.002 0 002.84 0l8.58-8.6 8.58 8.6a2.002 2.002 0 002.84 0 2.003 2.003 0 00.438-2.189 2.002 2.002 0 00-.438-.65L46.82 44z"
                            ></path>
                            <circle
                              cx="43.5"
                              cy="43.5"
                              r="41"
                              stroke="#CC2727"
                              strokeWidth="5"
                            ></circle>
                          </svg>
                        </div>
                        <p className="font-inter font-bold text-xl mb-6">
                          Lokasi Diluar Jangkauan Homecare
                        </p>

                        <button
                          onClick={() => setCheckDistance(false)}
                          className="bg-gray-100 px-6 py-2 font-inter focus:outline-none hover:bg-gray-50"
                        >
                          Kembali
                        </button>
                      </div>
                    </div>
                  )}

                  <Input
                    withOutline
                    label="Keterangan Tambahan Alamat"
                    type="text"
                    name="keterangan-alamat"
                    className="mb-5"
                    value={noteAlamat}
                    onChange={(e) => {
                      setNoteAlamat(e.target.value);
                      return setFixDataAlamat({
                        ...fixDataAlamat,
                        note: e.target.value,
                      });
                    }}
                    fixValue={(value) => value}
                  />
                </>
              )}

              <Input
                withDate
                withDateNow
                disableDay
                serviceLocationId={fixLokasi}
                label="Tanggal Vaksin"
                type="text"
                name="tgl-vaksinasi"
                className="mb-5"
                fixValue={(e) => {
                  getAntrianCount(e);
                  setTglVaksin(e);
                }}
                value={tglVaksin}
                onChange={(e) => {
                  return setTglVaksin(e.target.value);
                }}
                ref={register({
                  required: true,
                })}
              />

              {tglVaksin !== '' && (
                <p className="mb-7 flex justify-between font-inter text-sm">
                  <span className="font-bold text-gray1 ">
                    {registrationCount.registration_count || 0} Orang Terdaftar,
                    dari maksimal {registrationCount.registration_limit || 100}{' '}
                    orang
                  </span>
                  <Button
                    title="Tambah kuota antrian"
                    className="  text-blue1 rounded-3"
                    onClick={() => {}}
                  />
                </p>
              )}

              <Input
                withDropdown
                label="Pilih Dokter"
                type="text"
                name="pilih-dokter"
                className="mb-5"
                options={kategoriDokter}
                value={fixKategoriDokter.title}
                fixValue={(value) => {
                  setFixKategoriDokter({ id: value.id, title: value.name });
                  let hargaDokter = [];
                  hargaDokter.push(value.service_price);
                  setFixHarga([...fixHarga, value.service_price]);
                }}
                ref={register({
                  required: true,
                })}
                placeholder="Pilih Dokter"
              />

              <Input
                withDropdown
                label="Vaksintor"
                type="text"
                name="vaksinator"
                className="mb-5"
                options={
                  fixKategoriDokter.id === 1
                    ? kategoriDokter[0]?.doctors
                    : kategoriDokter[1]?.doctors
                }
                value={vaksinator.title}
                placeholder="Pilih Vaksinator"
                fixValue={(value) =>
                  SetVaksinator({ id: value.id, title: value.fullname })
                }
                ref={register({
                  required: true,
                })}
              />

              <Input
                withDropdown
                label="Shift"
                type="text"
                name="shift"
                className="mb-5"
                options={listShiftId}
                value={shiftId.title}
                serviceLocationId={fixLokasi.id}
                placeholder="Shift"
                fixValue={(value) =>
                  setShiftId({ id: value.id, title: value.name })
                }
                ref={register({
                  required: true,
                })}
              />

              <Input
                withOutline
                label="Nomor Yang Bisa Di Hubungi"
                type="text"
                name="nomor-hp"
                className="mb-5"
                placeholder="ex: 0851231223123"
                value={noHp}
                onChange={(e) => setNoHp(e.target.value)}
                ref={register({
                  required: true,
                })}
              />

              <Input
                withDropdown
                label="Metode Pembayaran"
                type="text"
                name="metode-pembayaran"
                className="mb-5"
                options={fixMetodePembayaran}
                value={IdMetodePembayaran.title}
                fixValue={(value) =>
                  setIdMetodePembayaran({ id: value.id, title: value.name })
                }
                ref={register({
                  required: true,
                })}
                placeholder="Pilih Metode Pembayaran"
              />

              <Input
                withDropdown
                label="Status Pembayaran"
                type="text"
                name="status-pembayaran"
                className="mb-5"
                options={statusPembayaran}
                value={fixStatusPembayaran.title}
                fixValue={(value) =>
                  setFixStatusPembayaran({ id: value.id, title: value.name })
                }
                ref={register({
                  required: true,
                })}
                placeholder="Pilih Status Pembayaran"
              />

              {isShowPaymentChannel && (
                <Input
                  withDropdown
                  label="Payment Channel"
                  type="text"
                  name="payement-channel"
                  className="mb-5"
                  options={allPaymentChannel}
                  value={fixPaymentChannel.title}
                  fixValue={(value) =>
                    setFixPaymentChannel({ id: value.id, title: value.name })
                  }
                  ref={register({
                    required: true,
                  })}
                  placeholder="Pilih Payment Channe;"
                />
              )}

              <Input
                withDropdown
                label="Status Antrian"
                type="text"
                name="status-antrian"
                className="mb-5"
                serviceLocationId={fixLokasi}
                options={statusAntrian}
                value={fixStatusAntrian.title}
                fixValue={(value) =>
                  setFixStatusAntrian({ id: value.id, title: value.name })
                }
                ref={register({
                  required: true,
                })}
                placeholder="Pilih Status Antrian"
              />
            </div>
          </div>
        </div>

        <div className="md:block lg:flex lg:justify-between lg:items-center -mx-9 px-10 pt-7 border-t-8 border-blue2">
          <div className="font-inter">
            <h2 className="font-bold text-lg text-black">
              Total Biaya :
              <span className="font-bold text-xl mr-7">{`Rp. ${formatNumber(
                fixHarga.reduce((acc, curr) => acc + curr, 0)
              )}`}</span>
            </h2>
          </div>

          <div className="md:mt-4 md:flex md:justify-between md:-mx-4 lg:mx-0 lg:mt-0">
            <ButtonNew
              isBordered
              isDisabled={isDisabledCancel}
              className="w-full md:w-1/2 md:mx-4 lg:mx-0 lg:mr-5 lg:w-44 rounded-3 cursor-pointer my-4 md:my-0"
              onClick={onClickCancel}
            >
              Reset
            </ButtonNew>

            {/* <Button
            isPrimary
            title="Buat Antrian"
            className=" w-full md:w-1/2 md:mx-4 lg:mx-0 lg:w-44 rounded-3"
            onClick={handleClick}
          /> */}
            <Button
              type="submit"
              isPrimary
              title="Buat Antrian"
              className=" w-full md:w-1/2 md:mx-4 lg:mx-0 lg:w-44 rounded-3"
              onClick={handleSubmit(onSubmit)}
            />
          </div>
        </div>

        {showLoading && (
          <Modal
            isLoading={tunggu}
            message={
              response.status === 200
                ? 'Antrian Baru Berhasil Disimpan'
                : 'Antrian Gagal Disimpan'
            }
            closeModal={() => {
              setTunggu(false);
              setShowLoading(false);
              if (response.status === 200) {
                props.history.push('/dashboard/antrian-klinik');
              }
            }}
          />
        )}
      </div>
    </form>
  );
}
