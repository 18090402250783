import React from 'react';
import propTypes from 'prop-types';
import { InputValidation } from './InputValidation';
import InputIcon from './InputIcon';
import { InputOutline } from './InputOutline';
import { InputDropdown } from './InputDropdown';
import InputDate from './InputDate';
import { InputSelect } from './InputSelect';

export const Input = React.forwardRef(
  (
    {
      id,
      type,
      name,
      placeholder,
      autoFocus,
      onChange,
      onFocus,
      onBlur,
      onClick,
      className,
      autoComplete,
      value,
      withIcon,
      withOutline,
      withValidation,
      withDropdown,
      withDate,
      withSelect,
      icon,
      label,
      options,
      fixValue,
      readOnly,
      serviceLocationId,
      withDateNow,
      disableDay,
      defaultValue,
    },
    ref
  ) => {
    if (withValidation) {
      return (
        <InputValidation
          type={type}
          name={name}
          icon={icon}
          placeholder={placeholder}
          onChange={onChange}
          className={className}
          autoComplete={autoComplete}
          ref={ref}
        />
      );
    }

    if (withIcon) {
      return (
        <InputIcon
          type={type}
          name={name}
          placeholder={placeholder}
          onChange={onChange}
          autoComplete={autoComplete}
          className={className}
          value={value}
          icon={icon}
        />
      );
    }

    if (withOutline) {
      return (
        <InputOutline
          id={id}
          type={type}
          name={name}
          label={label}
          value={value}
          onChange={onChange}
          className={className}
          placeholder={placeholder}
          autoFocus={autoFocus}
          onFocus={onFocus}
          onBlur={onBlur}
          onClick={onClick}
          readOnly={readOnly}
          ref={ref}
        />
      );
    }

    if (withSelect) {
      return (
        <InputSelect
          id={id}
          type={type}
          name={name}
          label={label}
          value={value}
          defaultValue={defaultValue}
          onChange={onChange}
          className={className}
          placeholder={placeholder}
          onFocus={onFocus}
          onBlur={onBlur}
          onClick={onClick}
          readOnly={readOnly}
          ref={ref}
          options={options}
        />
      );
    }

    if (withDropdown) {
      return (
        <InputDropdown
          options={options}
          type={type}
          name={name}
          label={label}
          onChange={onChange}
          className={className}
          placeholder={placeholder}
          onFocus={onFocus}
          onBlur={onBlur}
          fixValue={(value) => fixValue(value)}
          value={value}
          innerRef={ref}
          serviceLocationId={serviceLocationId}
        />
      );
    }

    if (withDate) {
      return (
        <InputDate
          placeholder={placeholder}
          type={type}
          name={name}
          label={label}
          withDateNow={withDateNow}
          innerRef={ref}
          className={className}
          onChange={onChange}
          value={value}
          serviceLocationId={serviceLocationId}
          disableDay={disableDay}
          fixValue={(value) => fixValue(value)}
        />
      );
    }

    return (
      <input
        name={name}
        type={type}
        className="p-4 rounded-xl text-black1 font-roboto text-sm focus:outline-none focus:ring focus:ring-blue-300 focus:border-blue-200"
        value={value}
        placeholder={placeholder}
        onChange={onChange}
      />
    );
  }
);

Input.propTypes = {
  name: propTypes.string.isRequired,
  type: propTypes.string,
  placeholder: propTypes.string,
};
