import { DummyVaksin } from 'assets';
import API from 'config/Axios';
import React, { useRef, useState } from 'react';

import { formatNumber } from 'utils';
import { Input } from '..';
import Button from '../Button';

export default function FormTambahProduk({ closeModal, data }) {
  const [dataNewVaksin, setDataNewVaksin] = useState({
    brand_name: '',
    price: '',
    stok: '',
    vaccine_type_ids: '',
    image: '',
  });
  const [tempLinkImg, setTempLinkImg] = useState('');

  const updateFormatNumber = (val) => {
    let separator;
    let number_string = val.replace(/[^,\d]/g, '').toString(),
      split = number_string.split(','),
      sisa = split[0].length % 3,
      rupiah = split[0].substr(0, sisa),
      ribuan = split[0].substr(sisa).match(/\d{3}/gi);

    // tambahkan titik jika yang di input sudah menjadi angka ribuan
    if (ribuan) {
      separator = sisa ? '.' : '';
      rupiah += separator + ribuan.join('.');
    }

    rupiah = split[1] != undefined ? rupiah + ',' + split[1] : rupiah;

    setDataNewVaksin({ ...dataNewVaksin, price: rupiah });
  };

  const inputFile = useRef(null);
  const onButtonClick = () => {
    inputFile.current.click();
  };

  const handleClickSimpan = async () => {
    const tempPrice = dataNewVaksin.price;
    const clearFormatRupiah = tempPrice.replace(/\D/g, '');
    let tipeId;
    if (dataNewVaksin?.vaccine_type_ids?.length) {
      console.log('masuk sini');
      tipeId = dataNewVaksin?.vaccine_type_ids?.map((item) => item.id);
    }

    const sendData = new FormData();

    sendData.append('brand_name', dataNewVaksin.brand_name);
    console.log('tipeId', tipeId);
    if (tipeId) {
      tipeId.map((id) => sendData.append('vaccine_type_ids[]', id));
    }
    sendData.append('price', parseInt(clearFormatRupiah));
    sendData.append('stock', dataNewVaksin.stok);
    sendData.append('image', dataNewVaksin.image);

    await API.post(`/staff/vaccines`, sendData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
      .then((res) => {
        closeModal();
        return window.location.reload();
      })
      .catch((err) => console.log('err', err));
  };

  const handleUploadGambar = (e) => {
    let img = e.target.files[0];
    let tempLink = URL.createObjectURL(img);
    setTempLinkImg(tempLink);
    setDataNewVaksin({ ...dataNewVaksin, image: img });
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-60  flex justify-center items-center z-30">
      <div
        className="w-auto max-h-full bg-white flex flex-col py-16 px-12 relative z-30 overflow-y-scroll scrollbar-none"
        style={{ width: 420, minHeight: 555 }}
      >
        <div className="absolute top-5 right-7">
          <svg
            className="cursor-pointer"
            width="26"
            height="26"
            viewBox="0 0 26 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={closeModal}
          >
            <path
              d="M15.8199 13.0001L24.4199 4.42013C24.7965 4.04352 25.0081 3.53273 25.0081 3.00013C25.0081 2.46752 24.7965 1.95673 24.4199 1.58013C24.0433 1.20352 23.5325 0.991943 22.9999 0.991943C22.4673 0.991943 21.9565 1.20352 21.5799 1.58013L12.9999 10.1801L4.41988 1.58013C4.04327 1.20352 3.53248 0.991943 2.99988 0.991943C2.46727 0.991943 1.95649 1.20352 1.57988 1.58013C1.20327 1.95673 0.991694 2.46752 0.991694 3.00013C0.991694 3.53273 1.20327 4.04352 1.57988 4.42013L10.1799 13.0001L1.57988 21.5801C1.39242 21.7661 1.24363 21.9873 1.1421 22.231C1.04056 22.4747 0.988281 22.7361 0.988281 23.0001C0.988281 23.2642 1.04056 23.5256 1.1421 23.7693C1.24363 24.013 1.39242 24.2342 1.57988 24.4201C1.7658 24.6076 1.98701 24.7564 2.23072 24.8579C2.47444 24.9594 2.73585 25.0117 2.99988 25.0117C3.2639 25.0117 3.52531 24.9594 3.76903 24.8579C4.01275 24.7564 4.23395 24.6076 4.41988 24.4201L12.9999 15.8201L21.5799 24.4201C21.7658 24.6076 21.987 24.7564 22.2307 24.8579C22.4744 24.9594 22.7359 25.0117 22.9999 25.0117C23.2639 25.0117 23.5253 24.9594 23.769 24.8579C24.0128 24.7564 24.2339 24.6076 24.4199 24.4201C24.6073 24.2342 24.7561 24.013 24.8577 23.7693C24.9592 23.5256 25.0115 23.2642 25.0115 23.0001C25.0115 22.7361 24.9592 22.4747 24.8577 22.231C24.7561 21.9873 24.6073 21.7661 24.4199 21.5801L15.8199 13.0001Z"
              fill="#C8C8C8"
            />
          </svg>
        </div>

        <div className="flex justify-between items-center mb-7">
          <h2 className="font-inter font-semibold text-lg">Detail Produk</h2>
        </div>

        <div className="">
          <Input
            withOutline
            name="merek-vaksin"
            label="Merek Vaksin"
            value={dataNewVaksin.brand_name}
            onChange={(e) =>
              setDataNewVaksin({
                ...dataNewVaksin,
                brand_name: e.target.value,
              })
            }
            className="mb-8"
          />

          <div className="">
            <Input
              withSelect
              name="jenis-vaksin"
              label="Jenis Vaksin"
              // defaultValue={data.vaccine_types}
              value={(value) =>
                setDataNewVaksin({
                  ...dataNewVaksin,
                  vaccine_type_ids: value,
                })
              }
            />
          </div>

          <Input
            type="number"
            withOutline
            name="stok-vaksin"
            label="Stok"
            value={dataNewVaksin.stok}
            onChange={(e) =>
              setDataNewVaksin({
                ...dataNewVaksin,
                stok: e.target.value,
              })
            }
            className="my-8"
          />

          <Input
            withOutline
            name="harga-vaksin"
            label="Harga Vaksin"
            value={dataNewVaksin.price.toLocaleString()}
            onChange={(e) => updateFormatNumber(e.target.value)}
            className="my-8"
          />

          <h2 className="font-inter font-medium text-sm">Gambar Produk</h2>

          <div className="mt-5 flex items-center w-full">
            {tempLinkImg === '' ? (
              <div
                className="w-24 h-24 border-2 border-dashed border-black2 rounded-3 flex justify-center items-center"
                onClick={() => onButtonClick()}
              >
                <svg
                  width="49"
                  height="50"
                  viewBox="0 0 49 50"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M38.8227 4.64453V8.74407H34.8296V14.8934C36.4182 14.8934 37.9416 15.5412 39.0649 16.6945C40.1882 17.8477 40.8192 19.4118 40.8192 21.0427V43.5901C40.8192 44.1338 40.6089 44.6551 40.2344 45.0395C39.86 45.424 39.3522 45.6399 38.8227 45.6399H10.8713C10.3418 45.6399 9.83395 45.424 9.45953 45.0395C9.0851 44.6551 8.87476 44.1338 8.87476 43.5901V21.0427C8.87476 19.4118 9.5058 17.8477 10.6291 16.6945C11.7523 15.5412 13.2758 14.8934 14.8643 14.8934V8.74407H10.8713V4.64453H38.8227ZM34.8296 18.9929H14.8643C14.3348 18.9929 13.827 19.2089 13.4526 19.5933C13.0782 19.9777 12.8678 20.499 12.8678 21.0427V41.5404H36.8261V21.0427C36.8261 20.499 36.6158 19.9777 36.2414 19.5933C35.867 19.2089 35.3591 18.9929 34.8296 18.9929ZM26.8435 23.0924V27.192H30.8366V31.2915H26.8415L26.8435 35.3911H22.8504L22.8485 31.2915H18.8574V27.192H22.8504V23.0924H26.8435ZM30.8366 8.74407H18.8574V14.8934H30.8366V8.74407Z"
                    fill="#0085FF"
                  />
                </svg>
              </div>
            ) : (
              <div className="w-24 h-20 ">
                <img
                  src={tempLinkImg}
                  alt={dataNewVaksin.brand_name}
                  className="bg-cover"
                />
              </div>
            )}

            <div className="ml-6">
              <input
                accept="image/*"
                onChange={(e) => handleUploadGambar(e)}
                type="file"
                id="file"
                ref={inputFile}
                style={{ display: 'none' }}
              />
              <Button
                title="Upload Gambar"
                className="text-blue1 font-bold  text-sm"
                onClick={() => onButtonClick()}
              />
            </div>
          </div>
        </div>

        <Button
          isPrimary
          title="Simpan"
          className="font-bold  text-sm rounded-3 mx-auto mt-10"
          onClick={() => handleClickSimpan()}
        />
      </div>
    </div>
  );
}
