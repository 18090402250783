import {
  getAllPaymentMethod,
  getRuangBertugas,
  postRuangBertugas,
} from 'config/Redux/action';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { Route } from 'react-router-dom';
import {
  getLocalRuangBertugas,
  setLocalRuangBertugas,
  getLocalRole,
  setPaymentMethod,
  setPaymentChannel,
} from '../../utils';
import {
  AntrianHomeCare,
  AntrianKlinik,
  DetailPasienVaksin,
  Modal,
  Pengukuran,
  Sidebar,
  TambahAntrian,
  JadwalVaksin,
  PetaVaksinasi,
  AntrianAkanDatang,
  DaftarKeluarga,
  Profile,
  ProfilKeluarga,
  DaftarPesanan,
  DetailPesanan,
  DaftarProduk,
  DaftarDokter,
  CheckinPasien,
  Broadcast,
} from '../../components';
import Newnew from 'components/molecules/TambahAntrian/Newnew';
import API from 'config/Axios';

export default function Dashboard(props) {
  const [showModal, setShowModal] = useState(false);
  const [ruangBertugas, setRuangBertugas] = useState([]);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  const RuangBetugas = async (value) => {
    setLocalRuangBertugas(value);
    return props.history.replace('/dashboard/antrian-klinik');
  };

  const _getRuangBertugas = async () => {
    const ruangBetugasStaff = await dispatch(getRuangBertugas('/staff/rooms'));
    setRuangBertugas(ruangBetugasStaff);
  };

  const _getAllPaymentMethod = async () => {
    const paymentMethod = await dispatch(
      getAllPaymentMethod('/staff/payments/methods')
    );
    setPaymentMethod(JSON.stringify(paymentMethod));
  };

  const _getAllPaymentChannel = () => {
    API.get('/staff/payment-channel')
      .then((res) => {
        const tempData = res.data?.order?.map((item) => ({
          ...item,
          name: item.name?.replace('_', ' '),
        }));
        setPaymentChannel(JSON.stringify(tempData || []));
      })
      .catch((err) => err);
  };

  useEffect(() => {
    _getRuangBertugas();
    _getAllPaymentMethod();
    _getAllPaymentChannel();
  }, []);

  if (localStorage.getItem('role_id') !== '1') {
    if (localStorage.getItem('ruang bertugas') === null) {
      return (
        <Modal
          type="ruang-bertugas"
          closeModal={() => setShowModal(!showModal)}
          value={(value) => RuangBetugas(value)}
          options={ruangBertugas}
        />
      );
    }
  }

  return (
    <div className="h-screen flex">
      <div className="flex-none">
        <Sidebar
          {...props}
          dataUser={user}
          ruangBertugas={getLocalRuangBertugas()}
          role={getLocalRole()}
        />
      </div>

      <div className="pl-2 h-screen w-full bg-blue2 overflow-hidden">
        <div className="flex-grow">
          <Route
            path="/dashboard/antrian-klinik"
            exact
            component={AntrianKlinik}
          />

          <Route
            path="/dashboard/antrian-klinik/tambah-antrian"
            component={TambahAntrian}
          />

          <Route
            path="/dashboard/detail-pasien-vaksin/:id"
            component={DetailPasienVaksin}
          />

          <Route path="/dashboard/pengukuran/:id" component={Pengukuran} />

          <Route
            path="/dashboard/antrian-homecare"
            component={AntrianHomeCare}
          />

          <Route
            path="/dashboard/jadwal-vaksinasi/:id"
            component={JadwalVaksin}
          />
          <Route path="/dashboard/tes" component={Newnew} />

          <Route
            path="/dashboard/homecare/peta-vaksinasi"
            component={PetaVaksinasi}
          />

          <Route path="/dashboard/daftar-pesanan" component={DaftarPesanan} />

          <Route
            path="/dashboard/detail-pesanan/:id"
            component={DetailPesanan}
          />

          <Route
            path="/dashboard/antrian-akan-datang"
            component={AntrianAkanDatang}
          />

          <Route path="/dashboard/daftar-keluarga" component={DaftarKeluarga} />

          <Route path="/dashboard/profil/:id" component={Profile} />

          <Route
            path="/dashboard/profil-keluarga/:id"
            component={ProfilKeluarga}
          />

          <Route path="/dashboard/daftar-produk" component={DaftarProduk} />
          <Route path="/dashboard/daftar-dokter" component={DaftarDokter} />
          <Route path="/dashboard/broadcast" component={Broadcast} />
        </div>
      </div>
    </div>
  );
}
