import { IconLoading, IconNoPhoto, IconCloseRed } from 'assets';
import { Button, LabelStatus, Modal } from 'components/atoms';
import { Header } from 'components/atoms/Header/Header';
import { TextContent } from 'components/atoms/Text/TextContent';
import { TitleContent } from 'components/atoms/Text/TitleContent';
import API from 'config/Axios';
import {
  checkTicket,
  getDetailPasien,
  getMerekVaksin,
  getRiwayatVaksinasi,
  tambahRiwayatVaksinasi,
  setDetailPasienChange,
  getOnePaymentMethod,
} from 'config/Redux/action';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { formatDate, formatNumber, formatDateAsc } from 'utils';
import { CardVaccine } from './Components/CardVaccine/CardVaccine';
import { CustomerQueueDetail } from './Components/CustomerQueueDetail/CustomerQueueDetail';
import { ModalAddVaccineType } from './Components/ModalAddVaccineType/ModalAddVaccineType';
import { PatientVaccineDetail } from './Components/PatientVaccineDetail/PatientVaccineDetail';

export default function DetailPasienVaksin(props) {
  const [showModalStatusAntrian, setShowModalStatusAntrian] = useState({
    show: false,
    lokasiVaksin: '',
  });
  const [showModalStatusPembayaran, setShowModalStatusPembayaran] =
    useState(false);
  const [showModalRiwayat, setShowModalRiwayat] = useState(false);
  const [statusAntrian, setStatusAntrian] = useState('');
  const [statusPembayaran, setStatusPembayaran] = useState('');
  const [detailPasien, setDetailPasien] = useState('');
  const [riwayatVaksinasi, setRiwayatVaksinasi] = useState('');
  const [showModalTambahJenisVaksin, setShowModalTambahJenisVaksin] =
    useState(false);
  const [ticketAvailable, setTicketAvailable] = useState(false);
  const [listVaksin, setListVaksin] = useState('');
  const [deleteVaksin, setDeleteVaksin] = useState([]);
  const [modalNomorBatch, setModalNomorBatch] = useState({
    show: false,
    id: '',
  });
  const [konfirmasiPembayaran, setKonfirmasiPembayaran] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState('');

  const isLoading = useSelector((state) => state.isLoading);
  const btnDisabled = useSelector((state) => state.btnDisabled);
  const [disabledButton, setDisabledButton] = useState(false);

  const newSendData = {};

  const tambahJenisVaksin = {
    family_member_id: '',
    vaccine_id: '',
    date_taken: '',
  };

  const dispatch = useDispatch();

  const Antrian = (value) => {
    setStatusAntrian(value);
  };

  const Pembayaran = (value) => {
    setStatusPembayaran(value);
  };

  const _getDetailPasien = async () => {
    const data = await dispatch(
      getDetailPasien(`/staff/queues/${props.match.params.id}`)
    );

    setDetailPasien(data);

    const merekVaksin = await dispatch(getMerekVaksin(`/staff/vaccines`));
    setListVaksin(merekVaksin.vaccines);

    const riwayatVaksinasi = await dispatch(
      getRiwayatVaksinasi('/staff/vaccinations/history?', data.family_member_id)
    );

    if (!riwayatVaksinasi.status) {
      setRiwayatVaksinasi(riwayatVaksinasi.vaccinations);
    }

    if (data.order.payment_method_id) {
      const payment = await dispatch(
        getOnePaymentMethod(
          `/staff/payments/methods/${data.order.payment_method_id}`
        )
      );
      setPaymentMethod(payment);
    }
  };

  const _getRiwayatVaksin = async (detailPaseinChange) => {
    const merekVaksin = await dispatch(getMerekVaksin(`/staff/vaccines`));
    setListVaksin(merekVaksin.vaccines);

    const riwayatVaksinasi = await dispatch(
      getRiwayatVaksinasi(
        '/staff/vaccinations/history?',
        detailPaseinChange.family_member_id
      )
    );
    setRiwayatVaksinasi(riwayatVaksinasi.vaccinations);
  };

  const detailPaseinChange = useSelector((state) => state.detailPasien);

  useEffect(() => {
    if (detailPaseinChange === '') {
      // setBtnDisabled(true);
      _getDetailPasien();
    } else if (detailPaseinChange.id === parseInt(props.match.params.id)) {
      // setBtnDisabled(false);
      setDetailPasien(detailPaseinChange);
      _getRiwayatVaksin(detailPaseinChange);
    } else {
      _getDetailPasien();
    }
  }, [showModalRiwayat]);

  const onClickTransactionDetail = () => {
    props.history.push(`/dashboard/detail-pesanan/${detailPasien?.order_id}`);
  };

  const onChangeQueueStatus = () => {
    setShowModalStatusAntrian({
      show: true,
      lokasiVaksin: detailPasien.order.service_location_id,
    });
  };

  const onClickPatientVaccineDetail = () => {
    localStorage.setItem('family_id', detailPasien?.family_member.family_id);
    props.history.push(`/dashboard/profil/${detailPasien?.family_member_id}`);
  };

  const onShowModalBatch = (data) => {
    data
      ? setModalNomorBatch({
          show: true,
          id: data.id,
        })
      : setModalNomorBatch(data);
  };

  const onShowModalAddVaccine = () => {
    setShowModalTambahJenisVaksin(!showModalTambahJenisVaksin);
  };

  const onSetPatientDetail = (data) => {
    setDetailPasien(data);
  };

  const onGetPatientDetail = async (data) => {
    await dispatch(setDetailPasienChange(data));
  };

  const onAddVaccine = async (data) => {
    detailPasien.vaccinations.push(data);
    await dispatch(setDetailPasienChange(detailPasien));
  };

  const onSaveVaccine = async () => {
    setDisabledButton(true);
    const newVaksin = [];
    const newData = { ...detailPasien };

    newSendData.queue_id = newData.id;
    newSendData.doctor_id = newData.order.doctor_id;
    newSendData.nurse_id = parseInt(localStorage.getItem('perawat_id'));

    newData.vaccinations.map((item, i) => {
      const data = {};

      if (Number.isInteger(item.id)) {
        data.id = item.id;
      } else {
        data.date_taken = moment().format('YYYY-MM-DD');
      }

      data.vaccine_id = item.vaccine_id;
      data.batch = item.batch;

      newVaksin.push(data);
    });

    newSendData.new_vaccinations = newVaksin;
    newSendData.deleted_vaccinations = deleteVaksin;

    await dispatch(
      tambahRiwayatVaksinasi('/staff/vaccinations/mass-store', newSendData)
    );
    _getDetailPasien().finally(() => {
      setDisabledButton(false);
      setDeleteVaksin([]);
    });
  };

  const onDeletedVaccine = (vaccineId) => {
    setDeleteVaksin([...deleteVaksin, vaccineId]);
  };

  if (detailPasien === '') {
    return (
      <div className="h-screen bg-white pt-11 pb-8 px-9 overflow-y-scroll flex justify-center items-center">
        <div className="">
          <div className="flex justify-center mb-16 animate-spin">
            <img
              src={IconLoading}
              alt="icon loading"
              className=" justify-center"
            />
          </div>
          <h3 className="font-inter font-bold text-2xl text-center">
            Silahkan Tunggu
          </h3>
        </div>
      </div>
    );
  }

  const vaccinationDate =
    detailPasien.order.service_location_id === 1 ? ' - Klinik' : ' - Home Care';

  const descVaksinator =
    detailPasien.order.doctor?.doctor_category.service_price === 0
      ? ' - Gratis'
      : `- Rp. ${
          detailPasien.order.doctor?.doctor_category?.service_price || '-'
        }`;

  return (
    <div className="flex flex-col bg-white justify-between pb-4 h-screen  overflow-y-scroll">
      <div className="bg-white pt-11 px-9">
        <Header
          title="Detail Antrian"
          subTitle={`a.n ${detailPasien.family_member?.fullname} - ${detailPasien?.order.order_number}`}
          data={detailPasien}
          withMeasurement
          status={detailPasien?.queue_status?.name}
          onChangeStatus={onChangeQueueStatus}
          createdAt={detailPasien?.order.date_plan}
        />

        <div className="md:block lg:flex mt-8 min-w-full">
          <div className="w-full lg:w-6/12 ">
            <div className="font-inter pb-3 mb-6">
              <CustomerQueueDetail
                data={detailPasien}
                onClick={onClickTransactionDetail}
              />

              <PatientVaccineDetail
                data={detailPasien}
                onClick={onClickPatientVaccineDetail}
              />
            </div>
            <div className="mb-11">
              <TitleContent text="Tempat Pemberian Vaksin" />

              {detailPasien.order.service_location_id === 2 ? (
                <div className="">
                  <TextContent text="Home Care" />

                  <p className="font-inter font-normal text-sm text-black2">
                    {detailPasien.order.address?.full_address || '-'}{' '}
                    <Button
                      title="> Detail Alamat"
                      className="font-inter font-normal text-sm text-gray1"
                    />
                  </p>
                </div>
              ) : (
                <div>
                  <TextContent text="Klinik" />
                </div>
              )}
            </div>

            <div className="">
              <div className="mb-11">
                <TitleContent text="Tanggal Vaksinasi" />
                <TextContent
                  text={`${formatDateAsc(
                    detailPasien.order.date_plan
                  )} ${vaccinationDate}`}
                />
              </div>

              <div className="mb-8">
                <TitleContent text="Vaksinator" />
                <TextContent
                  text={`${detailPasien.order?.doctor?.fullname} ${descVaksinator}`}
                />
              </div>

              <div className="">
                <TitleContent text="Metode Pembayaran" />
                <div className="flex items-center">
                  <TextContent text={paymentMethod?.name || '-'} />

                  {/* Transfer Bank Check Manual */}
                  {detailPasien.order?.payment_method_id === 3 && (
                    <Button
                      title="&gt; Konfirmasi Pembayaran"
                      className="text-blue1"
                      onClick={() => setKonfirmasiPembayaran(true)}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="w-full lg:w-1/12"></div>

          <div className="w-full lg:w-6/12 md:mt-10 lg:my-0 ">
            <div className="font-inter pb-3 mb-6">
              <div className="flex justify-between items-center mb-4">
                <h2 className="font-bold text-lg text-black">Vaksin</h2>
                <Button
                  title="+ Tambah Jenis Vaksin"
                  className="text-blue1 text-sm font-semibold"
                  onClick={() => setShowModalTambahJenisVaksin(true)}
                />

                {showModalTambahJenisVaksin && (
                  <ModalAddVaccineType
                    onShowModal={onShowModalAddVaccine}
                    listVaksin={listVaksin}
                    onAddVaccine={onAddVaccine}
                  />
                )}
              </div>

              {detailPasien.vaccinations.length === 0 ? (
                <p className="font-inter text-sm text-gray3 mb-8">
                  Belum Ada Vaksin Yang Dipilih
                </p>
              ) : (
                <>
                  {detailPasien.vaccinations.map((item, i) => {
                    return (
                      <CardVaccine
                        data={item}
                        detailPasien={detailPasien}
                        dataModalBatch={modalNomorBatch}
                        onShowModalBatch={onShowModalBatch}
                        onSetPatientDetail={onSetPatientDetail}
                        onGetPatientDetail={onGetPatientDetail}
                        onDeletedVaccine={onDeletedVaccine}
                      />
                    );
                  })}
                </>
              )}

              <Button
                isLoading={disabledButton}
                title={`${
                  !btnDisabled ? 'Simpan Perubahan' : 'Tidak Ada Perubahan'
                }`}
                className={`mb-5 rounded-3 font-inter font-bold text-sm  text-white  ${
                  !btnDisabled
                    ? `bg-blue1 border border-blue1 hover:border-blue3 px-7 py-3 hover:bg-blue3`
                    : 'bg-gray1 border border-gray1 hover:border-gray3 px-7 py-3   hover:bg-gray3'
                }`}
                isDisabled={btnDisabled}
                onClick={onSaveVaccine}
              />
            </div>

            <div className="flex justify-between">
              <h2 className="font-bold text-lg text-black">
                Riwayat Vaksinasi
              </h2>
              <Button
                title="+ Tambahkan Riwayat Vaksinasi"
                className="font-inter font-bold text-sm rounded-3 ml-12 text-blue1"
                onClick={() => setShowModalRiwayat(true)}
              />
            </div>

            <div className="mt-4">
              {riwayatVaksinasi ? (
                riwayatVaksinasi.map((riwayat, i) => {
                  const vaccineTypes = riwayat.vaccine_types
                    .map((item) => item.name)
                    ?.join(', ');

                  return (
                    <div
                      className="flex justify-between items-center my-2"
                      key={i}
                    >
                      <p className="font-inter font-bold text-sm text-gray1">
                        {`${riwayat.vaccine?.brand_name} - ${vaccineTypes}`}
                      </p>

                      <p className="font-inter text-sm text-gray1">
                        {formatDateAsc(riwayat.date_taken)}
                      </p>
                    </div>
                  );
                })
              ) : (
                <p className="font-inter text-sm text-gray3">
                  Belum Ada Riwayat Vaksinasi
                </p>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="py-4 px-9 flex justify-between items-center">
        <div className="w-full">
          <div className="flex border-b pb-6 border-t mt-8 border-gray3">
            <div className="w-full mt-6 font-inter flex items-center flex-wrap lg:flex-nowrap">
              <p className="font-bold text-lg  lg:mb-0 md:mr-3">
                Sub-Total
                <span className="ml-11 font-bold text-xl">{`Rp. ${formatNumber(
                  detailPasien.total_price
                )}`}</span>
              </p>

              <div className="ml-11 " onClick={() => console.log('click')}>
                <p
                  className="font-inter font-semibold text-sm text-blue1 cursor-pointer"
                  onClick={onClickTransactionDetail}
                >
                  Lihat Total Tagihan &gt;
                </p>
              </div>

              <div className="lg:ml-12 flex items-center justify-between">
                <LabelStatus
                  withOutline
                  type={detailPasien.order.payment_status?.name}
                  title={detailPasien.order.payment_status?.name}
                />

                {/* <button
                className="font-bold text-gray1 whitespace-nowrap lg:ml-4 hover:text-blue3 focus:outline-none focus:text-blue1 ml-4"
                onClick={() =>
                  setShowModalStatusPembayaran(!showModalStatusPembayaran)
                }
              >
                Ubah Status Pembayaran &gt;
              </button> */}
              </div>
            </div>
          </div>
          <div className="w-full flex items-center justify-between mt-16">
            <Button
              isPrimaryOutline
              isBtnOutlineSecondary
              title="Kembali"
              className="rounded-3"
              onClick={() => props.history.goBack()}
            />

            <div className="">
              <Link
                title="Ukur Pasien"
                className="mr-4 text-center rounded-3 bg-blue1 border border-blue1 hover:border-blue3 px-7 py-3 font-inter font-bold text-sm text-white hover:bg-blue3"
                to={`/dashboard/pengukuran/${props.match.params.id}`}
              >
                Ukur Pasien
              </Link>

              <Button
                className={`text-center rounded-3 px-7 py-3 font-inter font-bold text-sm  ${
                  isLoading
                    ? `bg-gray1 border border-gray1 hover:border-gray1 text-white hover:bg-gray1   `
                    : `bg-blue1 border border-blue1 hover:border-blue3 text-white hover:bg-blue3`
                }  `}
                title={isLoading ? `Loading...` : `Jadwal Vaksin Selanjutnya`}
                onClick={async () => {
                  try {
                    await dispatch(
                      checkTicket(
                        `/staff/family-members/ticket-availability/${detailPasien.family_member_id}`
                      )
                    );
                    props.history.push(
                      `/dashboard/jadwal-vaksinasi/${props.match.params.id}`
                    );
                  } catch (error) {
                    setTicketAvailable(true);
                  }
                }}
              />
              {/* <Link
            title="Jadwal Vaksin Selanjutnya"
            className="text-center rounded-3 bg-blue1 border border-blue1 hover:border-blue3 px-7 py-3 font-inter font-bold text-sm text-white hover:bg-blue3"
            to={`/dashboard/jadwal-vaksinasi/${props.match.params.id}`}
          >
            Jadwal Vaksin Selanjutnya
          </Link> */}
            </div>
          </div>
        </div>
      </div>

      {showModalStatusAntrian.show && (
        <Modal
          type="update-status-antrian"
          closeModal={() => setShowModalStatusAntrian(!showModalStatusAntrian)}
          idPasien={detailPasien.id}
          serviceLocationId={showModalStatusAntrian.lokasiVaksin}
          value={(value) => Antrian(value)}
          tes={() => _getDetailPasien()}
        />
      )}
      {showModalStatusPembayaran && (
        <Modal
          type="update-status-pembayaran"
          idPasien={detailPasien.order_id}
          closeModal={() =>
            setShowModalStatusPembayaran(!showModalStatusPembayaran)
          }
          value={Pembayaran}
          statusPembayaran={() => _getDetailPasien()}
        />
      )}

      {showModalRiwayat && (
        <Modal
          type="riwayat-vaksinasi"
          idPasien={detailPasien.family_member_id}
          closeModal={() => setShowModalRiwayat(false)}
        />
      )}

      {ticketAvailable && (
        <Modal
          type="ready-ticket"
          closeModal={() => {
            return setTicketAvailable(false);
          }}
        />
      )}

      {konfirmasiPembayaran && (
        <Modal
          type="konfirmasi-pembayaran"
          idPasien={detailPasien.order_id}
          closeModal={() => {
            return setKonfirmasiPembayaran(false);
          }}
        />
      )}
    </div>
  );
}
