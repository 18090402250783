import React from "react";
import RuangBertugas from "./RuangBertugas";
import StatusAntrian from "./StatusAntrian";
import StatusPembayaran from "./StatusPembayaran";
import JenisVaksin from "./JenisVaksin";
import { IconDone, IconLoading } from "assets";
import Button from "../Button";
import NoInternet from "./NoInternet";
import RiwayatVaksinasi from "./RiwayatVaksinasi";
import RiwayatJenisVaksin from "./RiwayatJenisVaksin";
import BatalkanJadwal from "./BatalkanJadwal";
import DetailTambahJenisVaksin from "./DetailTambahJenisVaksin";
import ReadyTicket from "./ReadyTicket";
import NewTambahJenisVaksin from "./NewTambahJenisVaksin";
import TambahNomorBatch from "./TambahNomorBatch";
import PenyelesaianVaksinasi from "./PenyelesaianVaksinasi";
import PaymentHomecare from "./PaymentHomecare";
import KonfirmasiPembayaran from "./KonfirmasiPembayaran";
import TambahPasien from "./TambahPasien";
import EditProfile from "./EditProfile";
import TambahDaftarKeluarga from "./TambahDaftarKeluarga";
import FormDaftarKeluarga from "./FormDaftarKeluarga";
import DetailProduk from "./DetailProduk";
import FormEditProduk from "./FormEditProduk";
import FormTambahProduk from "./FormTambahProduk";
import HapusProdukOrDokter from "./HapusProdukOrDokter";
import ProfilDokter from "./ProfilDokter";
import TambahDokter from "./TambahDokter";
import RiwayatVaksinasiPasienBaru from "./RiwayatVaksinasiPasienBaru";

export default function Modal({
  type,
  closeModal,
  value,
  idPasien,
  tes,
  options,
  statusPembayaran,
  modalId,
  isLoading,
  message,
  data,
  isNextVaksin,
  fixData,
  serviceLocationId,
  onChange,
  isDeleteProduct,
  isDeleteDoctor,
  isDeleteFamily,
}) {
  if (type === "ruang-bertugas") {
    return (
      <RuangBertugas
        closeModal={closeModal}
        value={(asd) => value(asd)}
        options={options}
      />
    );
  }

  if (type === "update-status-antrian") {
    return (
      <StatusAntrian
        serviceLocationId={serviceLocationId}
        closeModal={closeModal}
        value={(asd) => value(asd)}
        idPasien={idPasien}
        tes={tes}
      />
    );
  }

  if (type === "update-status-pembayaran") {
    return (
      <StatusPembayaran
        closeModal={closeModal}
        value={value}
        idPasien={idPasien}
        statusPembayaran={statusPembayaran}
      />
    );
  }

  if (type === "jenis-vaksin") {
    return (
      <JenisVaksin
        isNextVaksin={isNextVaksin}
        data={data}
        modalId={modalId}
        closeModal={closeModal}
        value={value}
        idPasien={idPasien}
        statusPembayaran={statusPembayaran}
      />
    );
  }

  if (type === "no-internet") {
    return <NoInternet />;
  }

  if (type === "riwayat-vaksinasi") {
    return <RiwayatVaksinasi closeModal={closeModal} idPasien={idPasien} />;
  }

  if (type === "jenis-vaksin-riwayat") {
    return <RiwayatJenisVaksin closeModal={closeModal} value={value} />;
  }

  if (type === "batalkan-vaksinasi") {
    return (
      <BatalkanJadwal
        closeModal={closeModal}
        value={(asd) => value(asd)}
        idPasien={idPasien}
        tes={tes}
      />
    );
  }

  if (type === "detail-tambah-jenis-vaksin") {
    return (
      <NewTambahJenisVaksin
        closeModal={closeModal}
        option={options}
        fixData={fixData}
      />
      // <DetailTambahJenisVaksin
      //   isNextVaksin={isNextVaksin}
      //   data={data}
      //   modalId={modalId}
      //   closeModal={closeModal}
      //   value={value}
      //   idPasien={idPasien}
      //   statusPembayaran={statusPembayaran}
      // />
    );
  }

  if (type === "tambah-nomor-batch") {
    return (
      <TambahNomorBatch
        closeModal={closeModal}
        fixData={fixData}
        modalId={modalId}
      />
    );
  }

  if (type === "ready-ticket") {
    return <ReadyTicket closeModal={closeModal} />;
  }

  if (type === "penyelesaian-vaksinasi") {
    return (
      <PenyelesaianVaksinasi
        closeModal={closeModal}
        value={(asd) => value(asd)}
        idPasien={idPasien}
        tes={tes}
      />
    );
  }

  if (type === "payment-homecare") {
    return <PaymentHomecare closeModal={closeModal} idPasien={idPasien} />;
  }
  if (type === "konfirmasi-pembayaran") {
    return <KonfirmasiPembayaran idOrder={idPasien} closeModal={closeModal} />;
  }

  if (type === "tambah-pasien") {
    return <TambahPasien closeModal={closeModal} />;
  }

  if (type === "edit-profile") {
    return (
      <EditProfile closeModal={closeModal} idPasien={idPasien} data={data} />
    );
  }

  if (type === "tambah-daftar-keluarga") {
    return (
      <TambahDaftarKeluarga
        closeModal={closeModal}
        data={data}
        onChange={onChange}
      />
    );
  }

  if (type === "form-daftar-keluarga") {
    return (
      <FormDaftarKeluarga
        closeModal={closeModal}
        data={data}
        onChange={onChange}
      />
    );
  }

  if (type === "detail-produk") {
    return <DetailProduk closeModal={closeModal} idProduk={idPasien} />;
  }

  if (type === "tambah-produk") {
    return (
      <FormTambahProduk
        closeModal={closeModal}
        idProduk={idPasien}
        data={data}
      />
    );
  }

  if (type === "form-edit-produk") {
    return (
      <FormEditProduk closeModal={closeModal} idProduk={idPasien} data={data} />
    );
  }

  if (type === "hapus-produk-dokter") {
    return (
      <HapusProdukOrDokter
        isDeleteProduct={isDeleteProduct}
        isDeleteDoctor={isDeleteDoctor}
        isDeleteFamily={isDeleteFamily}
        closeModal={closeModal}
        data={data}
        tes={tes}
      />
    );
  }

  if (type === "profil-dokter") {
    return <ProfilDokter closeModal={closeModal} data={data} />;
  }

  if (type === "tambah-dokter") {
    return <TambahDokter closeModal={closeModal} data={data} tes={tes} />;
  }

  if (type === "riwayat-vaksinasi-pasien-baru") {
    return <RiwayatVaksinasiPasienBaru closeModal={closeModal} data={data} />;
  }

  return (
    <div className="fixed inset-0 bg-black bg-opacity-60 flex justify-center items-center z-30">
      <div
        className="w-auto max-h-full bg-white px-20 flex flex-col justify-center items-center relative z-30"
        style={{ width: 512, height: 450 }}
      >
        {isLoading ? (
          <div className="">
            <div className="flex justify-center mb-16 animate-spin">
              <img
                src={IconLoading}
                alt="icon loading"
                className=" justify-center"
              />
            </div>
            <h3 className="font-inter font-bold text-2xl text-center">
              Silahkan Tunggu
            </h3>
          </div>
        ) : (
          <div className="">
            <div className="flex justify-center mb-16 ">
              <img
                src={IconDone}
                alt="icon selesai"
                className=" justify-center"
              />
            </div>
            <h3 className="font-inter font-bold text-2xl text-center mb-10">
              {message}
            </h3>

            <div className="flex justify-center">
              <Button
                isBtnOutlineSecondary
                isPrimaryOutline
                title="Lanjutkan"
                onClick={closeModal}
              />
            </div>
          </div>
        )}

        {/* {!isLoading && (
          <div className="">
            <div className="flex justify-center mb-16 ">
              <img
                src={IconDone}
                alt="icon selesai"
                className=" justify-center"
              />
            </div>
            <h3 className="font-inter font-bold text-2xl text-center mb-10">
              {message}
            </h3>

            <div className="flex justify-center">
              <Button
                isBtnOutlineSecondary
                isPrimaryOutline
                title="Lanjutkan"
                onClick={closeModal}
              />
            </div>
          </div>
        )} */}
      </div>
    </div>
  );
}
