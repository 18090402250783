import DaftarKeluarga from 'assets/icon/DaftarKeluarga';
import React from 'react';
import { Link } from 'react-router-dom';
import { Antrian, Logout, MasterData } from '../../../assets';

const typeIcon = (type) => {
  if (type === 'Antrian Klinik') {
    return <Antrian />;
  } else if (type === 'Antrian Homecare') {
    return <Antrian />;
  } else if (type === 'Antrian Akan Datang') {
    return <Antrian />;
  } else if (type === 'Master Data') {
    return <MasterData />;
  } else if (type === 'Daftar Pasien') {
    return <DaftarKeluarga />;
  } else if (type === 'Daftar Pesanan') {
    return <Antrian />;
  } else if (type === 'Daftar Produk') {
    return <Antrian />;
  }
  return <DaftarKeluarga />;
};

export default function WithSubMenu({
  listSubMenu,
  title,
  onClick,
  href,
  isActive,
  icon,
  showTitle,
  ...props
}) {
  const getNavLinkClass = (path) => {
    return props.location.pathname === path ? ' active' : '';
  };

  return (
    <div className="accordion md:w-full ">
      <div
        className={`flex md:w-full justify-between items-center w-full py-4 focus:outline-none side-menu cursor-pointer ${
          isActive ? 'active' : ''
        }`}
        onClick={onClick}
      >
        <div className="flex items-center">
          {typeIcon(title)}

          <p
            className={`${
              showTitle ? '' : 'hidden'
            } xl:block ml-3 font-inter font-medium text-sm `}
          >
            {title}
          </p>
        </div>

        <svg
          className={`${
            showTitle ? '' : 'hidden'
          } xl:block transform arrowLeft ${isActive ? '-rotate-90' : ''}`}
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.5 3L5.5 8.5L10.5 13.5"
            className="stroke-current"
            stroke="#313131"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>

      <ul
        className={`origin-top xl:block xl:ml-6 overflow-hidden font-inter font-medium text-sm ${
          isActive ? 'animate-SideBarListAnimation' : 'h-0 invisible opacity-0'
        }  ${showTitle ? 'ml-6' : ''}`}
      >
        {listSubMenu.map((menu, i) => {
          return (
            <li
              className={`flex mb-2 items-center cursor-pointer ${getNavLinkClass(
                menu.link
              )}`}
              key={i}
              onClick={() => props.history.push(menu.link)}
            >
              {typeIcon(menu.title)}
              <p
                // to={menu.link}
                className={`hover:underline py-1 block hover:text-blue1 ml-2 xl:block ${
                  showTitle ? '' : 'hidden'
                } `}
              >
                {menu.title}
              </p>
            </li>
          );
        })}
      </ul>
    </div>
  );
}
