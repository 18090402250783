import { CheckinPasien, RunningQueue } from 'components';
import store from 'config/Redux/store';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Dashboard, Login } from '../pages';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-tooltip/dist/react-tooltip.css';
import { PrintInvoice } from 'components/molecules/PrintInvoice/PrintInvoice';

function App() {
  return (
    <Provider store={store}>
      <div>
        <Router>
          <Route path="/" exact component={Login} />
          <Route path="/dashboard" component={Dashboard} />
          <Route exact path="/checkin-pasien" component={CheckinPasien} />
          <Route exact path="/running-queue" component={RunningQueue} />
          <Route exact path="/print-invoice/:id" component={PrintInvoice} />
        </Router>
        <ToastContainer
          autoClose={3000}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </div>
    </Provider>
  );
}

export default App;
