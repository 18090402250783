import axios from 'axios';
import { getAccessToken } from 'utils';
import API from '../../Axios';
import {
  IS_LOADING,
  SET_TOKEN,
  DATA_USER,
  LOKASI_VAKSIN,
  NO_INTERNET,
  DETAIL_PASIEN,
  BTN_DISABLED,
  BTN_PETA_VAKSINASI,
  BTN_PETA_DISABLE,
  DAFTAR_TITIK_TUJUAN,
} from '../type';

export const doLogin = (url, data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    localStorage.clear();
    const newData = {
      role: 'staff',
      email: data.email,
      password: data.password,
    };

    dispatch({ type: IS_LOADING, value: true });

    axios
      .post(url, newData, {
        baseURL: process.env.REACT_APP_API_URL,
        headers: {
          Accept: 'application/json',
        },
      })
      .then((res) => {
        dispatch({ type: SET_TOKEN, value: res.data.access_token });
        dispatch({ type: DATA_USER, value: res.data.user });
        dispatch({ type: IS_LOADING, value: false });

        localStorage.setItem('token', res.data.access_token);
        localStorage.setItem('role_id', res.data.user.role_id);
        localStorage.setItem('role', res.data.user.role.name);
        localStorage.setItem('perawat_id', res.data.user.id);
        localStorage.setItem('name', res.data.user.name);

        resolve(res);
      })
      .catch((err) => {
        dispatch({ type: IS_LOADING, value: false });
        reject(err.response.status);
        // reject(err);
      });
  });
};

export const getRuangBertugas = (url) => (dispatch) => {
  return new Promise((resolve, reject) => {
    API.get(url)
      .then((res) => {
        resolve(res.data.rooms);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const postRuangBertugas = (url, id) => (dispatch) => {
  return new Promise((resolve, reject) => {
    const data = {
      _method: 'PATCH',
      room_id: id,
    };

    API.post(url, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getAntrianKlinik = (url, location, role) => (dispatch) => {
  return new Promise((resolve, reject) => {
    API.get(url, {
      params: {
        service_location_id: location,
        filtered_by_role: role,
      },
    })
      .then((res) => {
        dispatch({ type: NO_INTERNET, value: false });
        resolve(res.data.queues);
      })
      .catch((err) => {
        dispatch({ type: NO_INTERNET, value: true });
        reject(err);
      });
  });
};

export const getStatusAntrian = (url, location) => (dispatch) => {
  return new Promise((resolve, reject) => {
    API.get(url, {
      params: {
        service_location_id: location,
      },
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const updateStatusAntrian = (url, data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    const newData = {
      _method: 'PATCH',
      queue_status_id: data,
    };

    API.post(url, JSON.stringify(newData), {
      headers: {
        'content-type': 'application/json',
      },
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getLokasiPemberianVaksin = (url) => (dispatch) => {
  return new Promise((resolve, reject) => {
    API.get(url)
      .then((res) => {
        resolve(res.data.serviceLocations);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fixLokasiPemberianVaksin = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch({ type: LOKASI_VAKSIN, value: data });
  });
};

export const getDokter = (url) => (dispatch) => {
  return new Promise((resolve, reject) => {
    API.get(url)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getKategoriDokter = (url) => (dispatch) => {
  return new Promise((resolve, reject) => {
    API.get(url)
      .then((res) => {
        resolve(res.data.doctorCategories);
      })
      .catch((err) => {
        reject(err);
      });
  }).catch((e) => e.response);
};

export const getStatusPembayaran = (url) => (dispatch) => {
  return new Promise((resolve, reject) => {
    API.get(url)
      .then((res) => {
        resolve(res.data.paymentStatuses);
      })
      .catch((err) => {
        reject(err);
      });
  }).catch((e) => e.response);
};

export const updateStatusPembayaran = (url, data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    const newData = {
      _method: 'PATCH',
      payment_status_id: data,
    };

    API.post(url, JSON.stringify(newData), {
      headers: {
        'content-type': 'application/json',
      },
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getPungukuranPasien = (url) => (dispatch) => {
  return new Promise((resolve, reject) => {
    API.get(url)
      .then((res) => {
        resolve(res.data.queue);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const doPungukuranPasien = (url, data, id) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch({ type: IS_LOADING, value: true });
    const familyId = {
      family_member_id: id,
    };
    const newData = { ...data, ...familyId };
    API.post(url, newData)
      .then((res) => {
        dispatch({ type: IS_LOADING, value: false });
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  })
    .then((res) => res)
    .catch((err) => err);
};

export const updatePungukuranPasien = (url, data, id) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch({ type: IS_LOADING, value: true });
    const familyId = {
      _method: 'PATCH',
      family_member_id: id,
    };

    const newData = { ...data, ...familyId };

    API.post(url, newData)
      .then((res) => {
        dispatch({ type: IS_LOADING, value: false });
        resolve(res);
      })
      .catch((err) => {
        dispatch({ type: IS_LOADING, value: false });
        reject(err.response.status);
      });
  })
    .then((res) => res)
    .catch((err) => err);
};

export const getDetailPasien = (url) => (dispatch) => {
  return new Promise((resolve, reject) => {
    API.get(url)
      .then((res) => {
        resolve(res.data.queue);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getNamaPasien = (url) => (dispatch) => {
  return new Promise((resolve, reject) => {
    API.get(url)
      .then((res) => {
        resolve(res.data.familyMembers);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getJenisVaksin = (url) => (dispatch) => {
  return new Promise((resolve, reject) => {
    API.get(url)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getMerekVaksin = (url, key) => (dispatch) => {
  return new Promise((resolve, reject) => {
    API.get(url, {
      params: {
        search_keyword: key || '',
      },
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const tambahAntrian = (url, data) => (dispatch) => {
  dispatch({ type: IS_LOADING, value: true });
  return new Promise((resolve, reject) => {
    API.post(url, data)
      .then((res) => {
        dispatch({ type: IS_LOADING, value: false });
        resolve(res);
      })
      .catch((err) => {
        dispatch({ type: IS_LOADING, value: false });
        reject(err);
      });
  }).catch((e) => e.response);
};

export const jadwalVaksinasiSelanjutnya = (url, data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch({ type: IS_LOADING, value: true });

    API.post(url, data)
      .then((res) => {
        dispatch({ type: IS_LOADING, value: false });

        resolve(res);
      })
      .catch((err) => {
        dispatch({ type: IS_LOADING, value: false });
      });
  });
};

export const getRegistrationCount = (url, tanggal, shift) => (dispatch) => {
  return new Promise((resolve, reject) => {
    API.get(url, {
      params: {
        date: tanggal,
        shift_id: shift,
      },
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const tambahRiwayatVaksinasi = (url, data) => (dispatch) => {
  // dispatch({ type: IS_LOADING, value: true });
  return new Promise((resolve, reject) => {
    API.post(url, data)
      .then((res) => {
        dispatch({ type: BTN_DISABLED, value: true });
        resolve(res);
      })
      .catch((err) => {
        // dispatch({ type: IS_LOADING, value: false });
        reject(err);
      });
  });
};

export const getRiwayatVaksinasi = (url, id) => (dispatch) => {
  return new Promise((resolve, reject) => {
    API.get(url, {
      params: {
        family_member_id: id,
      },
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  }).catch((e) => e.response);
};

export const checkTicket = (url) => (dispatch) => {
  dispatch({ type: IS_LOADING, value: true });
  return new Promise((resolve, reject) => {
    API.get(url)
      .then((res) => {
        dispatch({ type: IS_LOADING, value: false });

        resolve(res);
      })
      .catch((err) => {
        dispatch({ type: IS_LOADING, value: false });
        reject(err.response.status);
      });
  });
};

export const setDetailPasienChange = (data) => (dispatch) => {
  dispatch({ type: BTN_DISABLED, value: false });
  dispatch({ type: DETAIL_PASIEN, value: data });
};

export const getShiftId = (url) => (dispatch) => {
  return new Promise((resolve, reject) => {
    API.get(url)
      .then((res) => {
        //
        resolve(res.data.shifts);
      })
      .catch((err) => {
        reject(err.response.status);
      });
  });
};

export const setBtnPetaVaksinasi = (data, disable) => (dispatch) => {
  dispatch({ type: BTN_PETA_VAKSINASI, value: parseInt(data) });
  dispatch({ type: BTN_PETA_DISABLE, value: disable });
};

export const getAllQueues = (url, params) => (dispatch) => {
  return new Promise((resolve, reject) => {
    API.get(url, {
      params,
    })
      .then((res) => {
        //
        resolve(res.data.queues);
      })
      .catch((err) => {
        reject(err.response.status);
      });
  });
};

export const getAllOrders = (url, params) => (dispatch) => {
  return new Promise((resolve, reject) => {
    API.get(url, {
      params,
    })
      .then((res) => {
        resolve(res.data.orders);
      })
      .catch((err) => {});
  });
};

export const getOneOrders = (url) => (dispatch) => {
  return new Promise((resolve, reject) => {
    API.get(url)
      .then((res) => {
        resolve(res.data.order);
      })
      .catch((err) => {
        reject(err.response.status);
      });
  });
};

export const setDaftarTitikTujuan = (data) => (dispatch) => {
  dispatch({ type: DAFTAR_TITIK_TUJUAN, value: data });
};

export const setHomecareQueue = (url, data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    API.patch(url, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.response.status);
      });
  });
};

export const getAllFamilyMember = (url) => (dispatch) => {
  return new Promise((resolve, reject) => {
    API.get(url)
      .then((res) => {
        resolve(res.data.familyMembers);
      })
      .catch((err) => {
        reject(err.response.status);
      });
  });
};

export const getFamilyMember = (url, params) => (dispatch) => {
  return new Promise((resolve, reject) => {
    if (params) {
      return API.get(url, { params })
        .then((res) => {
          resolve(res.data.familyMembers);
        })
        .catch((err) => {
          reject(err.response.status);
        });
    } else {
      API.get(url)
        .then((res) => {
          resolve(res.data.familyMember);
        })
        .catch((err) => {
          reject(err.response.status);
        });
    }
  });
};

export const acceptPayment = (url) => (dispatch) => {
  dispatch({ type: IS_LOADING, value: true });
  return new Promise((resolve, reject) => {
    API.post(url)
      .then((res) => {
        dispatch({ type: IS_LOADING, value: false });

        resolve(res);
      })
      .catch((err) => {
        dispatch({ type: IS_LOADING, value: false });

        reject(err.response.status);
      });
  });
};

export const rejectPayment = (url, data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    const notes = {
      note: data,
    };

    API.post(url, notes)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err.response.status);
      });
  });
};

export const getAllFamilyPostion = (url) => (dispatch) => {
  return new Promise((resolve, reject) => {
    API.get(url)
      .then((res) => {
        resolve(res.data.familyPositions);
      })
      .catch((err) => {
        reject(err.response.status);
      });
  });
};

export const updateFamilyMember = (url, data) => (dispatch) => {
  dispatch({ type: IS_LOADING, value: true });

  return new Promise((resolve, reject) => {
    API.post(url, data)
      .then((res) => {
        dispatch({ type: IS_LOADING, value: false });
        resolve(res.data);
      })
      .catch((err) => {
        dispatch({ type: IS_LOADING, value: false });
        reject(err.response.status);
      });
  });
};

export const getOnePaymentMethod = (url) => (dispatch) => {
  return new Promise((resolve, reject) => {
    API.get(url)
      .then((res) => {
        resolve(res.data.paymentMethod);
      })
      .catch((err) => {
        reject(err.response.status);
      });
  });
};

export const getDetailPayment = (url) => (dispatch) => {
  return new Promise((resolve, reject) => {
    API.get(url)
      .then((res) => {
        resolve(res.data.order);
      })
      .catch((err) => {
        reject(err.response.status);
      });
  });
};

export const getProfileFamily = (url) => (dispatch) => {
  return new Promise((resolve, reject) => {
    API.get(url)
      .then((res) => {
        localStorage.setItem('family_id', res.data.family.id);
        resolve(res.data.family);
      })
      .catch((err) => {
        reject(err.response.status);
      });
  });
};

export const getAllAddress = (url, familyId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    API.get(url, { params: { family_id: familyId } })
      .then((res) => {
        resolve(res.data.addresses);
      })
      .catch((err) => {
        reject(err.response.status);
      });
  });
};

export const getAllPaymentMethod = (url) => (dispatch) => {
  return new Promise((resolve, reject) => {
    API.get(url)
      .then((res) => {
        resolve(res.data.paymentMethods);
      })
      .catch((err) => {
        reject(err.response.status);
      });
  });
};

export const getSearchFamily = (url, keyword) => (dispatch) => {
  return new Promise((resolve, reject) => {
    API.get(url, { params: { keyword } })
      .then((res) => {
        resolve(res.data.Family);
      })
      .catch((err) => {
        reject(err.response.status);
      });
  });
};
