import {
  DirectionsService,
  DistanceMatrixService,
  GoogleMap,
  InfoWindow,
  Marker,
  useLoadScript,
} from '@react-google-maps/api';
import { findAllByTestId } from '@testing-library/dom';
import cx from 'classnames';
import {
  IconMarkerMaps,
  IconTelpon,
  IconMobilVaksin,
  IconLoading,
  ICCopy,
  IconMapPutih,
} from 'assets';
import { Button, LabelStatus, Modal } from 'components/atoms';
import { toast } from 'react-toastify';
import API from 'config/Axios';
import {
  getAntrianKlinik,
  setBtnPetaVaksinasi,
  updateStatusAntrian,
  getDetailPasien,
  setDaftarTitikTujuan,
  setHomecareQueue,
} from 'config/Redux/action';
import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useDispatch, useSelector } from 'react-redux';
import { HeaderHomecare } from './HeaderHomecare/HeaderHomecare';
import ICDragable from '../../../assets/icon/ic_round-drag-handle.svg';
import { Tooltip } from 'react-tooltip';
import { useHistory } from 'react-router';
import { ArrowPathIcon } from '@heroicons/react/24/outline';

const libraries = ['places'];
const mapContainerStyle = {
  width: '100%',
  height: '100vh',
};

const center = {
  lat: -5.1642407,
  lng: 119.436266,
};

const TEXT_BTN_STATUS = {
  Terkonfirmasi: 'Menuju Lokasi',
  'Menuju Lokasi': 'Dalam Perjalanan',
  'Dalam Perjalanan': 'Tiba di Lokasi',
  'Tiba di Lokasi': 'Tahap Vaksinasi',
  'Dalam Proses Vaksinasi': 'Selesai Vaksinasi',
};

export default function PetaVaksinasi(props) {
  const history = useHistory();

  const akunSopir = localStorage.getItem('name');
  const [textBtnDisabled, setTextBtnDisabled] = useState('Menuju Lokasi');
  const [textBtnActive, setTextBtnActive] = useState('Tiba Di Lokasi');
  const [listAntrian, setListAntrian] = useState(null);
  const [updateAntrian, setUpdateAntrian] = useState('');
  const [tes, setTes] = useState('');
  const [showInfoWindow, setShowInfoWindow] = useState(false);
  const [showId, setShowId] = useState(0);
  const [mobilVaksinLocation, setMobilVaksinLocation] = useState({
    lat: 0,
    lng: 0,
  });
  const [showPenyelesaianVaksinasi, setShowPenyelesaianVaksinasi] = useState({
    show: false,
    id: '',
  });
  const [paymentHomecare, setPaymentHomecare] = useState({
    show: false,
    id: '',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [checked, setChecked] = useState(true);
  const onShowToast = (name, address) => {
    navigator.clipboard.writeText(address);
    return toast.success(`Alamat ${name} di salin`);
  };
  const [isLoadingCurrentLocation, setIsLoadingCurrentLocation] =
    useState(false);
  const [centerMap, setCenterMap] = useState(center);

  const btnPetaVaksinasi = useSelector((state) => state.btnPetaVaksinasi);
  const btnPetaDisable = useSelector((state) => state.btnPetaDisable);
  const daftarTitikTujuan = useSelector((state) => state.daftarTitikTujuan);

  const dispatch = useDispatch();

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: 'AIzaSyB_73hYRCjyR5yeJXgQHcYjun6JGYGpM8E',
    libraries,
  });
  let startNumber = 1;

  // const moveit = () => {
  //   setInterval(() => {
  //     console.log(`increment`, (startNumber += 1));
  //   }, 1000);
  // };

  const currentLocation = () => {
    // navigator.geolocation.getCurrentPosition((position) => {
    //   setMobilVaksinLocation({
    //     ...mobilVaksinLocation,
    //     lat: position.coords.latitude,
    //     lng: position.coords.longitude,
    //   });
    // });
    // moveit();

    navigator.geolocation.watchPosition((position) => {
      setMobilVaksinLocation({
        ...mobilVaksinLocation,
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      });

      const updateKoordinatMobilVaksinasi = {
        _method: 'PATCH',
        content: `${position.coords.latitude},${position.coords.longitude}`,
      };

      if (akunSopir === 'Admin Homecare') {
        API.post('/staff/settings/1', updateKoordinatMobilVaksinasi)
          .then((res) => res)
          .catch((err) => err);
      }
    });
  };

  const handleClickBtnLokasi = async (id, textBtn) => {
    await dispatch(setBtnPetaVaksinasi(id, true));

    if (textBtn === 'Menuju Lokasi') {
      await dispatch(updateStatusAntrian(`/staff/queues/${id}`, 11));
      setTextBtnActive('Tiba Di Lokasi');
    } else if (textBtn === 'Tiba di Lokasi') {
      await dispatch(updateStatusAntrian(`/staff/queues/${id}`, 12));
      setTextBtnActive('Tahap Vaksinasi');
    } else if (textBtn === 'Tahap Vaksinasi') {
      await dispatch(updateStatusAntrian(`/staff/queues/${id}`, 6));
      setTextBtnActive('Selesai Vaksinasi');
    } else if (textBtn === 'Selesai Vaksinasi') {
      setTextBtnActive('Tiba Di Lokasi');
      setShowPenyelesaianVaksinasi({ show: true, id: id });
    }

    const updateAntrian = await dispatch(
      getDetailPasien(`/staff/queues/${id}`)
    );
    setUpdateAntrian(updateAntrian);
    setTes(updateAntrian);

    _getUpdateAntrian(updateAntrian);
  };

  const handleOnDragEnd = async (result) => {
    if (!result.destination) return;
    const items = Array.from(dataDragable);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    // const sendData = {};
    // const queue_id = [];
    // items.map((item) => {
    //   queue_id.push(item.id);

    //   return (sendData.queue_ids = queue_id);
    // });

    setDataDragable(items);

    // await dispatch(
    //   setHomecareQueue('/staff/queues/homecare-queue-order', sendData)
    // );
    if (btnPetaVaksinasi === -1) {
      return;
    }

    // await dispatch(setBtnPetaVaksinasi(result.destination.index));
  };

  const [dataDragable, setDataDragable] = useState(listAntrian);
  const [listPatientFinishedVaccination, setListPatientFinishedVaccination] =
    useState(null);

  const _getUpdateAntrian = async (updateAntrian) => {
    const tes = dataDragable?.map((item, i) => {
      if (item.id === updateAntrian.id) {
        return (listAntrian[i] = updateAntrian);
      }
      return item;
    });

    await dispatch(setDaftarTitikTujuan(tes));
    setDataDragable(tes);
  };

  const onUpdateVaccinationLocation = () => {
    setIsLoading(true);
    const tempPayload = dataDragable?.map((item) => ({
      queue_id: item?.id,
    }));

    const payload = {
      data: tempPayload,
    };

    API.put('/staff/queues/update-queue-number', payload)
      .then((res) => console.log('res >>', res))
      .catch((err) => console.log('err >>', err))
      .finally(() => {
        _getAntrianKlinik();
        setIsLoading(false);
      });
  };

  const filterListHomecare = (listAntrian) => {
    const checkStatus = listAntrian.filter(
      (item) => item.queue_status_id !== 7 && item.queue_status_id !== 1
    );
    const sortingData = checkStatus?.sort(
      (x, y) => (x?.queue_number || 0) - (y?.queue_number || 0)
    );

    setDataDragable(sortingData);

    const listFinishVaccination = listAntrian.filter(
      (item) => item.queue_status_id === 7
    );
    setListPatientFinishedVaccination(listFinishVaccination);
  };

  const _getAntrianKlinik = async () => {
    setIsLoading(true);
    if (localStorage.getItem('role') !== 'admin') {
      const list = await dispatch(
        getAntrianKlinik('/staff/queues/running?', 2, checked ? 1 : 0)
      );
      filterListHomecare(list);
      setIsLoading(false);
      return setListAntrian(list);
    }

    const list = await dispatch(
      getAntrianKlinik('/staff/queues/running?', 2, 0)
    );
    filterListHomecare(list);
    setIsLoading(false);

    return setListAntrian(list);
  };

  function onSuccessGetCurrentLocation(position) {
    setIsLoadingCurrentLocation(true);
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(position);
      }, 2000);
    })
      .then((res) => {
        setMobilVaksinLocation({
          ...mobilVaksinLocation,
          lat: res?.coords?.latitude,
          lng: res?.coords?.longitude,
        });
        return setCenterMap({
          lat: res?.coords?.latitude,
          lng: res?.coords?.longitude,
        });
      })
      .finally(() => {
        setIsLoadingCurrentLocation(false);
        toast.success('Berhasil update lokasi !', {
          position: 'top-center',
        });
      });
  }

  function onErrorGetCurrentLocation(err) {
    console.warn(`ERROR(${err.code}): ${err.message}`);
  }

  const onGetCurrentLocation = () => {
    navigator.geolocation.getCurrentPosition(
      onSuccessGetCurrentLocation,
      onErrorGetCurrentLocation,
      {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
      }
    );
  };

  useEffect(() => {
    _getAntrianKlinik();
    currentLocation();
  }, []);

  if (loadError) return 'Error loading maps';
  if (!isLoaded) return 'Loading maps';
  // if (!listAntrian) return "loading...";

  if (isLoading && !listAntrian) {
    return (
      <div className="h-screen bg-white pt-11 pb-8 px-9 overflow-y-scroll flex justify-center items-center">
        <div className="">
          <div className="flex justify-center mb-16 animate-spin">
            <img
              src={IconLoading}
              alt="icon loading"
              className=" justify-center"
            />
          </div>
          <h3 className="font-inter font-bold text-2xl text-center">
            Silahkan Tunggu
          </h3>
        </div>
      </div>
    );
  }

  return (
    <div className="flex h-screen">
      <div className="flex flex-col w-1/2 ">
        <div className="bg-white pt-8 pb-8 px-7 overflow-y-scroll scrollbar-none border-b-8 border-blue2 flex-1">
          <HeaderHomecare
            isLoading={isLoading}
            onUpdateVaccinationLocation={onUpdateVaccinationLocation}
          />

          {/* <p className="text-lg">
            latitude mobil :{' '}
            <span className="text-red-800 font-bold">
              {mobilVaksinLocation.lat}
            </span>{' '}
          </p>
          <p className="text-lg">
            longitude mobil :{' '}
            <span className="text-red-800 font-bold">
              {mobilVaksinLocation.lng}
            </span>{' '}
          </p> */}

          {!!dataDragable.length ? (
            <DragDropContext onDragEnd={handleOnDragEnd}>
              <Droppable droppableId="pasien-card">
                {(provided) => (
                  <div
                    className="bg-white"
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {dataDragable.map((item, index) => {
                      return (
                        <Draggable
                          key={item.id}
                          draggableId={item.id.toString()}
                          index={index}
                          isDragDisabled={
                            btnPetaDisable
                              ? parseInt(item.id) !== parseInt(btnPetaVaksinasi)
                              : false
                          }
                        >
                          {(provided) => (
                            <div
                              className="font-inter bg-white w-full border border-gray4 rounded-3 p-5 shadow-md mb-4"
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              ref={provided.innerRef}
                              key={index}
                            >
                              <div className="flex">
                                <p className="font-semibold text-black text-base mr-5">
                                  {item.queue_number || '000'}
                                </p>
                                <div>
                                  <div className="flex justify-between items-center mb-1">
                                    <p className="font-semibold text-black text-base">
                                      {item.family_member.fullname}
                                    </p>
                                    <LabelStatus
                                      type={item.queue_status.name}
                                      title={item.queue_status.name}
                                    />
                                  </div>
                                  <div className="flex justify-between align-top">
                                    <p className="font-normal text-gray1 text-sm mb-7 max-w-3/4">
                                      {item.order.address?.full_address}
                                    </p>
                                    <img
                                      src={ICDragable}
                                      alt={ICDragable}
                                      className="max-h-max"
                                    />
                                  </div>

                                  <div className="block lg:flex lg:justify-between lg:items-center">
                                    <div className="">
                                      <p className="font-normal text-gray1 text-xs mb-1">
                                        {item.family_member.fullname}
                                      </p>
                                      <p className="font-normal text-gray1 text-xs">
                                        {item.namaPasien}
                                      </p>
                                      <p className="font-normal text-gray1 text-xs">
                                        {item.order.phone_number}
                                      </p>
                                    </div>

                                    <div className="flex mt-4 lg:mt-0 gap-2">
                                      <div
                                        id="copy-icon"
                                        data-tooltip-content="Salin Alamat"
                                        className="cursor-pointer px-1 rounded-3 bg-white border-2 border-gray-500 font-inter font-bold text-gray-500 flex items-center"
                                        onClick={() =>
                                          onShowToast(
                                            item.family_member.fullname,
                                            item.order.address?.full_address
                                          )
                                        }
                                      >
                                        <ICCopy />
                                      </div>
                                      <Tooltip
                                        anchorId="copy-icon"
                                        place="bottom"
                                      />

                                      <div className="w-full lg:w-max flex justify-end">
                                        <Button
                                          type="button-icon"
                                          icon={IconMapPutih}
                                          className="px-0.5 rounded bg-white border-2 border-blue1 font-inter font-bold text-blue1"
                                          onClick={() => {
                                            const urlDirectionMaps = `https://www.google.com/maps/dir/?api=1&origin=${mobilVaksinLocation?.lat},${mobilVaksinLocation?.lng}&destination=${item?.order?.address?.latitude},${item?.order?.address?.longitude}`;

                                            window.open(urlDirectionMaps);
                                          }}
                                        />
                                      </div>

                                      <Button
                                        type="button-icon"
                                        onClick={() =>
                                          window.open(
                                            `tel:${item.order.phone_number}`
                                          )
                                        }
                                        icon={IconTelpon}
                                        className="px-2 rounded-3 bg-white border-2 border-blue1 font-inter font-bold text-blue1"
                                      />

                                      <Button
                                        id={item.id}
                                        name="btn-lokasi"
                                        title={
                                          TEXT_BTN_STATUS[
                                            item.queue_status.name
                                          ]
                                        }
                                        isDisabled={
                                          btnPetaDisable
                                            ? parseInt(item.id) !==
                                              parseInt(btnPetaVaksinasi)
                                            : false
                                        }
                                        onClick={async (e) => {
                                          await handleClickBtnLokasi(
                                            e.target.id,
                                            TEXT_BTN_STATUS[
                                              item.queue_status.name
                                            ]
                                          );
                                        }}
                                        className={
                                          btnPetaVaksinasi === -1 ||
                                          parseInt(item.id) ===
                                            parseInt(btnPetaVaksinasi)
                                            ? 'rounded-3 bg-blue1 px-3 py-1.5 font-inter text-sm text-white font-medium'
                                            : 'rounded-3 bg-gray1 px-3 py-1.5 font-inter text-sm text-white font-medium'
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          ) : (
            <p className="font-normal text-gray1 text-base text-center">
              No Data
            </p>
          )}

          {!!listPatientFinishedVaccination?.length ? (
            <div className="mt-11">
              <h1 className="font-inter font-bold text-2xl text-black2 mb-8">
                Pesanan Selesai Hari Ini
              </h1>

              {listPatientFinishedVaccination?.map((item, index) => (
                <div
                  className="font-inter bg-white w-full border border-gray4 rounded-3 p-5 shadow-md mb-4"
                  key={index}
                >
                  <div className="flex justify-between items-center mb-1">
                    <p className="font-semibold text-black text-base">
                      {item.family_member.fullname}
                    </p>
                    <LabelStatus
                      type={item.queue_status.name}
                      title={item.queue_status.name}
                    />
                  </div>
                  <div className="flex justify-between align-top">
                    <p className="font-normal text-gray1 text-sm mb-7">
                      {item.order.address?.full_address}
                    </p>
                  </div>

                  <div className="block lg:flex lg:justify-between lg:items-center">
                    <div className="">
                      <p className="font-normal text-gray1 text-xs mb-1">
                        {item.family_member.fullname}
                      </p>
                      <p className="font-normal text-gray1 text-xs">
                        {item.namaPasien}
                      </p>
                      <p className="font-normal text-gray1 text-xs">
                        {item.order.phone_number}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : null}
        </div>

        <div className="bg-white py-4 px-7">
          <Button
            isPrimaryOutline
            isBtnOutlineSecondary
            title="Kembali"
            className="rounded-3"
            onClick={() => props.history.goBack()}
          />
        </div>
      </div>

      <div className="bg-red-500 w-1/2 relative">
        <>
          <div
            id="btn-get-current-location"
            data-tooltip-content="Update Lokasi Terakhir Mobil Vaksin"
            className="bg-blue1 absolute z-40 right-4 top-4 p-2 rounded cursor-pointer hover:bg-blue-400"
            onClick={onGetCurrentLocation}
          >
            <ArrowPathIcon
              className={cx('h-5 w-5 text-white', {
                'animate-spin': isLoadingCurrentLocation,
              })}
            />
          </div>
          <Tooltip
            anchorId="btn-get-current-location"
            place="bottom"
            style={{ zIndex: 30 }}
          />
        </>
        <div className="">
          <GoogleMap
            mapContainerStyle={mapContainerStyle}
            zoom={15}
            center={centerMap}
            options={{ disableDefaultUI: true, zoomControl: true }}
          >
            {dataDragable.map((item, i) => {
              return (
                <div key={i}>
                  <Marker
                    onLoad={() => {}}
                    position={{
                      lat: parseFloat(item.order.address?.latitude),
                      lng: parseFloat(item.order.address?.longitude),
                    }}
                    icon={IconMarkerMaps}
                    visible={
                      btnPetaVaksinasi === -1
                        ? true
                        : parseInt(item.id) === btnPetaVaksinasi
                    }
                    onClick={() => {
                      setShowInfoWindow(true);
                      return setShowId(i);
                    }}
                  >
                    {showInfoWindow && i === showId && (
                      <InfoWindow
                        onLoad={() => {}}
                        position={{
                          lat: parseFloat(item.order.address?.latitude),
                          lng: parseFloat(item.order.address?.longitude),
                        }}
                        onCloseClick={() => setShowInfoWindow(false)}
                      >
                        <div className="font-inter">
                          <h1 className="font-semibold text-sm text-center">
                            {item.family_member.fullname}
                          </h1>
                          <p className="font-normal text-xs text-center">
                            {item.order.address?.full_address}
                          </p>
                        </div>
                      </InfoWindow>
                    )}
                  </Marker>
                </div>
              );
            })}

            <Marker
              onLoad={() => {}}
              position={mobilVaksinLocation}
              icon={{
                url: IconMobilVaksin,
                // scaledSize: new window.google.maps.Size(150, 150),
                // rotation: new window.google.maps.Rotation(45, 45),
              }}
            ></Marker>
          </GoogleMap>
        </div>
      </div>

      {showPenyelesaianVaksinasi.show && (
        <Modal
          idPasien={showPenyelesaianVaksinasi.id}
          type="penyelesaian-vaksinasi"
          closeModal={() => {
            setShowPenyelesaianVaksinasi(!showPenyelesaianVaksinasi);
            props.history.push('/dashboard/antrian-homecare');
            // setPaymentHomecare({
            //   show: true,
            //   id: showPenyelesaianVaksinasi.id,
            // });
          }}
        />
      )}

      {paymentHomecare.show && (
        <Modal
          idPasien={paymentHomecare.id}
          type="payment-homecare"
          closeModal={() => {
            setShowPenyelesaianVaksinasi(!showPenyelesaianVaksinasi);
            props.history.push('/dashboard/antrian-homecare');
          }}
        />
      )}
    </div>
  );
}
